/*eslint eqeqeq: "off"*/
import i18n from "i18next";
import { category } from "components/dashboard/DashboardComponentExports";
import {
  getUuid,
  getTickerUsingShortName,
  getExchangeRate,
  tickerTypes,
  accountLinkingService,
  getExchangeRateDetails,
  getTickerUsingId,
  convertCurrency,
  getSortKeyBetween,
  getAggregatorName,
  isAppInViewMode,
  isAssetCustodian,
  sanitizeIrr,
  getCustodianCost,
  calcCustodianOwnershipValue,
  recapChartTypes,
  getPercentageValue,
  getRecommendationForReports,
  pastValueInterval,
  recapChartOptions,
  RECAP_CATEGORY_TYPE_NETWORTH,
  reportTargetPercentageSelector,
  store,
  RECAP_CATEGORY_TYPE_ARCHIVED_ASSETS,
  RECAP_CATEGORY_TYPE_ARCHIVED_DEBTS,
  RECAP_CATEGORY_TYPE_DEBT,
  parseNumberStringToFloat,
  irrTypes,
  portfoliosSelector,
  getValueOfPropFromStringifiedJSON,
  portfolioSelector,
  capitalizeStringWithSpaces
} from "@kubera/common";
import getPrefixForDataCy from "components/grid/GridHelpers";

export class GridData {
  constructor(currency, sheets) {
    this.currency = currency;
    this.sheets = sheets;
    this.id = getUuid();
    this.forceShowSheetsTitles = true;
    this.autoFocusFirstCell = false;
    this.currentSheetIndex = 0;
    this.portfolioNetWorthData = null;
    this.category = null;
    this.isEditable = true;
    this.forceShowArchivedItems = false;
    this.changeData = null;
    this.onTotalChange = () => {};
    this.portfoliosCount = null;
    this.appendDefaultSheetToUrl = false;
  }

  isEmpty() {
    for (const sheet of this.sheets) {
      if (sheet.isEmpty() === false) {
        return false;
      }
    }
    return true;
  }

  getTotalValue(inCurrency) {
    var total = 0;
    for (const sheet of this.sheets) {
      total += sheet.getTotalValue(inCurrency);
    }
    return total;
  }

  getRow(id) {
    for (const sheet of this.sheets) {
      for (const section of sheet.sections) {
        const row = section.rows.find(row => row.id === id);
        if (row) {
          return row;
        }
      }
    }
    return null;
  }

  numberOfCompletedRows() {
    var completedCount = 0;
    for (const sheet of this.sheets) {
      for (const section of sheet.sections) {
        for (const row of section.rows) {
          if (row.isComplete()) {
            completedCount++;
          }
        }
      }
    }
    return completedCount;
  }

  numberOfRowsWithLinkingInProgress() {
    var count = 0;
    for (const sheet of this.sheets) {
      for (const section of sheet.sections) {
        for (const row of section.rows) {
          if (row.isLinkingInProgress()) {
            count++;
          }
        }
      }
    }
    return count;
  }

  hasMultipleTickersOfType(tickerType, minCount) {
    var tickerIds = new Set();

    for (const sheet of this.sheets) {
      for (const section of sheet.sections) {
        for (const row of section.rows) {
          if (!row.valueTickerId === false) {
            const valueTicker = getTickerUsingId(row.valueTickerId);
            if (valueTicker.type === tickerType && tickerIds.has(valueTicker.id) === false) {
              tickerIds.add(valueTicker.id);
            }

            if (tickerIds.size >= minCount) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }
}

const FREQUENCY_NEVER = { id: "none", value: 0, label: "Never", description: "" };
const FREQUENCY_WEEKLY = { id: "weekly", value: 1, label: "Weekly", description: "On Sunday" };
const FREQUENCY_BIWEEKLY = { id: "Biweekly", value: 6, label: "Biweekly", description: "On Sunday" };
const FREQUENCY_MONTHLY = { id: "monthly", value: 2, label: "Monthly", description: "On day 1" };
const FREQUENCY_QUARTERLY = {
  id: "quarterly",
  value: 3,
  label: "Quarterly",
  description: "January, April, July and October"
};
const FREQUENCY_HALFYEARLY = { id: "half_yearly", value: 4, label: "Half yearly", description: "January and July" };
const FREQUENCY_YEARLY = { id: "yearly", value: 5, label: "Annually", description: "1st January" };

export const GridSheetUpdateFrequency = [
  FREQUENCY_NEVER,
  FREQUENCY_WEEKLY,
  FREQUENCY_MONTHLY,
  FREQUENCY_QUARTERLY,
  FREQUENCY_HALFYEARLY,
  FREQUENCY_YEARLY,
  FREQUENCY_BIWEEKLY
];

export const DialogUpdateFrequency = [
  FREQUENCY_WEEKLY,
  FREQUENCY_BIWEEKLY,
  FREQUENCY_MONTHLY,
  FREQUENCY_QUARTERLY,
  FREQUENCY_HALFYEARLY,
  FREQUENCY_YEARLY,
  FREQUENCY_NEVER
];

export class GridSheetData {
  static maxAllowedSheets = 8;

  constructor(id, sortKey, name, sections) {
    this.id = id;
    this.sortKey = sortKey;
    this.name = name;
    this.sections = sections;
    this.updateFrequency = GridSheetUpdateFrequency[0];
  }

  isEmpty() {
    for (const section of this.sections) {
      if (section.isEmpty() === false) {
        return false;
      }
    }
    return true;
  }

  getLastSectionIndex() {
    return this.sections.length - 1;
  }

  getTotalValue(inCurrency) {
    var total = 0;
    var section;
    for (section of this.sections) {
      total += section.getTotalValue(inCurrency);
    }
    return total;
  }

  getAllRows() {
    var rows = [];
    for (const section of this.sections) {
      rows.push(...section.rows);
    }
    return rows;
  }

  getIrrDetails() {
    try {
      const details = JSON.parse(this.irr);
      if (!details.all.error === false) {
        return null;
      }
      return details;
    } catch (e) {
      return null;
    }
  }
}

export class GridSectionData {
  constructor(id, sortKey, name, rows, columns, columnIndexForCost, columnIndexForValue, isCollapsed) {
    this.id = id;
    this.sortKey = sortKey;
    this.name = name;
    this.rows = rows;
    this.columns = columns;
    this.columnIndexForCost = columnIndexForCost;
    this.columnIndexForValue = columnIndexForValue;
    this.isCollapsed = isCollapsed;
    this.isDragDisabled = false;

    this.forceShowTitle = false;
    this.showHeader = true;
    this.showFooter = true;
    this.showAddNewInFooter = true;
    this.totalValue = null;
    this.getContextMenuItems = section => [];
    this.getFooterContextMenuItems = () => [];
    this.hideRowsBeforeRowId = null;
    this.onShowPreviousClick = (e, firstVisibleIndex) => {};
    this.disableRowWindowing = false;
    this.onRenderTitle = null;
  }

  clone() {
    const newRows = this.rows.map(row => row.clone());
    const copyObject = new GridSectionData(
      this.id,
      this.sortKey,
      this.name,
      newRows,
      this.columns,
      this.columnIndexForCost,
      this.columnIndexForValue,
      this.isCollapsed
    );

    copyObject.forceShowTitle = this.forceShowTitle;
    copyObject.showHeader = this.showHeader;
    copyObject.showFooter = this.showFooter;
    copyObject.showAddNewInFooter = this.showAddNewInFooter;
    copyObject.totalValue = this.totalValue;
    copyObject.getContextMenuItems = this.getContextMenuItems;
    copyObject.getFooterContextMenuItems = this.getFooterContextMenuItems;
    copyObject.hideRowsBeforeRowId = this.hideRowsBeforeRowId;
    copyObject.onShowPreviousClick = this.onShowPreviousClick;
    copyObject.disableRowWindowing = this.disableRowWindowing;
    return copyObject;
  }

  getFirstEmptyRowIndex() {
    for (const [index, row] of this.rows.entries()) {
      if (row.isEmpty()) {
        return index;
      }
    }
    return this.rows.length;
  }

  getLastEmptyRowIndex() {
    for (var i = this.rows.length - 1; i >= 0; i--) {
      if (this.rows[i].isEmpty()) {
        return i;
      }
    }
    return 0;
  }

  getFirstEmptyRow() {
    for (const row of this.rows) {
      if (row.isEmpty()) {
        return row;
      }
    }
    return null;
  }

  isEmpty() {
    for (const row of this.rows) {
      if (row.isEmpty() === false) {
        return false;
      }
    }
    return true;
  }

  getTotalValue(inCurrency) {
    if (!this.totalValue === false) {
      return this.totalValue;
    }
    return this.getTotalForColumn(this.columnIndexForValue, inCurrency);
  }

  getTotalCost(inCurrency) {
    return this.getTotalForColumn(this.columnIndexForCost, inCurrency);
  }

  getIrrDetails() {
    try {
      const details = JSON.parse(this.irr);
      if (!details.all.error === false) {
        return null;
      }
      return details;
    } catch (e) {
      return null;
    }
  }

  shouldShowError() {
    for (const row of this.rows) {
      const lastCell = row.cells.at(-1);
      const secondLast = row.cells.at(-2);
      const isLinkingError =
        lastCell.type === cellType.LINK_ERROR && (row.isLinked || row.isLinking || row.isHeaderRow); //ignored footer condition because footer's last row will never be a link error
      const isTickerError = secondLast.type === cellType.TICKER_ERROR;
      const isLinkingFailure = lastCell.type === cellType.LINKING_FAILURE && (row.isLinked || row.isLinking);
      const isLinkingInputNeeded = lastCell.type === cellType.LINKING_INPUT_NEEDED && (row.isLinked || row.isLinking);
      if (isLinkingError || isTickerError || isLinkingFailure || isLinkingInputNeeded) {
        return true;
      }
    }
    return false;
  }

  getTotalForColumn(columnIndex, inCurrency) {
    if (
      columnIndex !== undefined &&
      this.columns[columnIndex] !== undefined &&
      this.columns[columnIndex].hideTotalForColumn === true
    ) {
      return 0;
    }
    if (columnIndex === this.columnIndexForValue && !this.totalValue === false) {
      return this.totalValue;
    }
    if (
      columnIndex !== undefined &&
      this.columns[columnIndex] !== undefined &&
      !this.columns[columnIndex].total === false
    ) {
      return this.columns[columnIndex].total;
    }

    const isCostColumn =
      columnIndex === this.columnIndexForCost && this.columnIndexForValue !== this.columnIndexForCost;

    var total = null;
    var row;
    for (row of this.rows) {
      if (row.isComplete() === false) {
        continue;
      }
      var cell = row.cells[columnIndex];
      if (!cell === true || !cell.invalidInputText === false) {
        continue;
      }
      if (isNaN(cell.value) === true || cell.value === null) {
        if (isCostColumn === true) {
          total = 0;
          break;
        }
        continue;
      }
      if (!total) total = 0;
      total += cell.getValueInCurrency(inCurrency, undefined, cell.ownership);
    }
    return total;
  }

  isColumnEmpty(sheetIndex, sectionIndex, columnIndex) {
    for (const [rowIndex, row] of this.rows.entries()) {
      const cell = row.cells[columnIndex];
      if (!cell.value === false) {
        return false;
      }
      if (
        !cell.getAccessoryView === false &&
        !cell.getAccessoryView(sheetIndex, sectionIndex, rowIndex, columnIndex) === false
      ) {
        return false;
      }
    }
    return true;
  }

  getLastSelectableColumnIndex(isEditable) {
    var index;
    for (let index = this.columns.length - 1; index >= 0; index--) {
      if (isEditable === true && this.columns[index].isEditable === true) {
        return index;
      }
      if (isEditable === false && this.columns[index].isSelectable === true) {
        return index;
      }
    }
    return index;
  }

  getFirstSelectableColumnIndex(isEditable) {
    var index;
    for (let index = 0; index <= this.columns.length - 1; index++) {
      if (isEditable === true && this.columns[index].isEditable === true) {
        return index;
      }
      if (isEditable === false && this.columns[index].isSelectable === true) {
        return index;
      }
    }
    return index;
  }

  getNextSelectableColumnIndex(isEditable, currentIndex) {
    var lastEditableColumnIndex = this.getLastSelectableColumnIndex(isEditable);
    if (currentIndex === lastEditableColumnIndex) {
      return currentIndex;
    }

    var nextIndex = currentIndex + 1;
    for (let index = nextIndex; index <= lastEditableColumnIndex; index++) {
      if (isEditable === true && this.columns[index].isEditable === true) {
        return index;
      }
      if (isEditable === false && this.columns[index].isSelectable === true) {
        return index;
      }
    }
    return -1;
  }

  getPreviousSelectableColumnIndex(isEditable, currentIndex) {
    if (currentIndex <= 0) {
      return currentIndex;
    }

    var nextIndex = currentIndex - 1;
    var firstEditableColumnIndex = this.getFirstSelectableColumnIndex(isEditable);
    for (let index = nextIndex; index >= firstEditableColumnIndex; index--) {
      if (isEditable === true && this.columns[index].isEditable === true) {
        return index;
      }
      if (isEditable === false && this.columns[index].isSelectable === true) {
        return index;
      }
    }
    return -1;
  }
}

export class GridRowData {
  static linkedAccountRemoveDescription({
    linkType = 0,
    linkProviderName = "",
    linkAccountName = "",
    linkAccountMask = "",
    linkProviderAccountId = "",
    linkAccountId = ""
  }) {
    if (linkType === accountLinkingService.KUBERA_PORTFOLIO) {
      return capitalizeStringWithSpaces(portfolioSelector(store.getState(), linkProviderAccountId)?.name);
    }

    if (
      [
        accountLinkingService.DOMAINS,
        accountLinkingService.ZILLOW,
        accountLinkingService.CARS,
        accountLinkingService.KUBERA_PORTFOLIO
      ].includes(linkType)
    ) {
      return "";
    }

    if (linkType === accountLinkingService.ZERION) {
      return linkAccountName;
    }
    if (
      linkType === accountLinkingService.IN_HOUSE_CRYPTO_API ||
      linkType === accountLinkingService.IN_HOUSE_CRYPTO_OAUTH ||
      linkType === accountLinkingService.IN_HOUSE_OAUTH
    ) {
      return linkProviderName + (linkAccountName ? " - " : "") + linkAccountName;
    }

    if (linkType && (linkProviderName || linkAccountName || linkAccountMask)) {
      return (
        linkProviderName +
        (linkAccountName ? " - " : "") +
        linkAccountName +
        (linkAccountMask ? " " : "") +
        linkAccountMask
      );
    }

    return "";
  }

  static linkedAccountDescription({
    linkType = 0,
    linkProviderName = "",
    linkAccountName = "",
    linkAccountMask = "",
    linkProviderAccountId = "",
    linkAccountId = ""
  }) {
    const aggregatorName = getAggregatorName(linkType);
    let formatAggregatorName = aggregatorName ? ` • ${aggregatorName}` : "";

    if (linkType === accountLinkingService.KUBERA_PORTFOLIO) {
      return `${i18n.t("portfolio")} ${linkAccountName}`;
    } else if (linkType === accountLinkingService.ZABO) {
      return linkProviderName + formatAggregatorName;
    } else if (
      [accountLinkingService.DOMAINS, accountLinkingService.ZILLOW, accountLinkingService.CARS].includes(linkType)
    ) {
      formatAggregatorName = "";
      linkProviderName = null;
    } else if (
      [
        accountLinkingService.ZERION,
        accountLinkingService.IN_HOUSE_CRYPTO_API,
        accountLinkingService.IN_HOUSE_CRYPTO_OAUTH,
        accountLinkingService.IN_HOUSE_OAUTH,
        accountLinkingService.CARS
      ].includes(linkType)
    ) {
      formatAggregatorName = "";
      if (
        (linkType === accountLinkingService.ZERION ||
          linkType === accountLinkingService.IN_HOUSE_CRYPTO_API ||
          linkType === accountLinkingService.IN_HOUSE_CRYPTO_OAUTH,
        linkType === accountLinkingService.IN_HOUSE_OAUTH)
      ) {
        linkAccountName = linkAccountMask;
        linkAccountMask = "";
      }
    } else if ([accountLinkingService.CARS].includes(linkType)) {
      linkProviderName = linkAccountId;
    } else {
      linkAccountName = "";
      linkAccountMask = "";
    }

    if (linkProviderName || linkAccountName || linkAccountMask) {
      const formatLinkProviderName = linkProviderName
        ? ![
            accountLinkingService.ZERION,
            accountLinkingService.IN_HOUSE_CRYPTO_API,
            accountLinkingService.IN_HOUSE_CRYPTO_OAUTH,
            accountLinkingService.IN_HOUSE_OAUTH
          ].includes(linkType)
          ? `${linkProviderName} - `
          : `${linkProviderName} `
        : "";
      const formatLinkAccountName = linkAccountName ? linkAccountName : "";
      const formatLinkAccountMask = linkAccountMask ? ` ${linkAccountMask}` : "";

      const providerNameStr = `${formatLinkProviderName}${formatLinkAccountName}`;
      const capitalizedStr = [accountLinkingService.DOMAINS].includes(linkType)
        ? providerNameStr
        : providerNameStr.replace(/(\b[a-z](?!\s))/g, x => x.toUpperCase()).replace(/\s-\s$/, "");

      return capitalizedStr + formatLinkAccountMask + formatAggregatorName;
    }
    return "";
  }

  constructor(id, sortKey, viewId, cells, cellIndexToStretch, removeIfEmpty, isComplete, isHeaderRow = false) {
    this.id = id;
    this.sortKey = sortKey;
    this.viewId = viewId;
    this.cells = cells;
    this.cellIndexToStretch = cellIndexToStretch;
    this.removeIfEmpty = removeIfEmpty;
    this.isComplete = isComplete;
    this.defaultCellIndexToSelect = 0;
    this.isHeaderRow = isHeaderRow;

    this.showHint = true;
    this.isUpdated = true;
    this.tsModified = 0;
    this.tsStart = 0;
    this.wasRowEverComplete = false;
    this.getContextMenuItems = row => [];
    this.isLinked = false;
    this.linkType = 0;
    this.linkedHoldingsCount = 0;
    this.linkStatus = 0;
    this.linkStatusInfo = null;
    this.linkContainer = null;
    this.linkProviderName = null;
    this.linkAccountName = null;
    this.linkAccountMask = null;
    this.isLinking = false;
    this.linkingFailed = false;
    this.linkingAccountsData = null;
    this.valueTickerId = null;
    this.isInvestable = true;
    this.isManaged = false;
    this.linkAccountId = null;
    this.parentId = null;
    this.linkProviderAccountId = null;
    this.autoAddedForEmptySections = false;
    this.availableCredit = null;
    this.isVerified = false;
    this.irrType = null;
    this.irr = null;
    this.onUpdateDelay = 0;
    this.actualValue = null;
    this.cmtdCap = null;
    this.cmtdCapTickerId = null;
    this.linkProviderId = null;
  }

  clone() {
    const copyObject = new GridRowData(
      this.id,
      this.sortKey,
      this.viewId,
      this.cells,
      this.cellIndexToStretch,
      this.removeIfEmpty,
      this.isComplete
    );

    copyObject.defaultCellIndexToSelect = this.defaultCellIndexToSelect;
    copyObject.showHint = this.showHint;
    copyObject.isUpdated = this.isUpdated;
    copyObject.tsModified = this.tsModified;
    copyObject.wasRowEverComplete = this.wasRowEverComplete;
    copyObject.getContextMenuItems = this.getContextMenuItems;
    copyObject.isLinked = this.isLinked;
    copyObject.linkType = this.linkType;
    copyObject.linkedHoldingsCount = this.linkedHoldingsCount;
    copyObject.linkStatus = this.linkStatus;
    copyObject.linkStatusInfo = this.linkStatusInfo;
    copyObject.isDragEnabled = this.isDragEnabled;
    copyObject.linkContainer = this.linkContainer;
    copyObject.linkProviderName = this.linkProviderName;
    copyObject.linkAccountName = this.linkAccountName;
    copyObject.linkAccountMask = this.linkAccountMask;
    copyObject.isLinking = this.isLinking;
    copyObject.linkingFailed = this.linkingFailed;
    copyObject.linkingAccountsData = this.linkingAccountsData;
    copyObject.valueTickerId = this.valueTickerId;
    copyObject.isInvestable = this.isInvestable;
    copyObject.isManaged = this.isManaged;
    copyObject.linkAccountId = this.linkAccountId;
    copyObject.tsStart = this.tsStart;
    copyObject.parentId = this.parentId;
    copyObject.linkProviderAccountId = this.linkProviderAccountId;
    copyObject.availableCredit = this.availableCredit;
    copyObject.isVerified = this.isVerified;
    copyObject.irrType = this.irrType;
    copyObject.irr = this.irr;
    copyObject.onUpdateDelay = this.onUpdateDelay;
    copyObject.actualValue = this.actualValue;
    copyObject.cmtdCap = this.cmtdCap;
    copyObject.cmtdCapTickerId = this.cmtdCapTickerId;
    copyObject.clubbingKey = this.clubbingKey;
    copyObject.linkProviderId = this.linkProviderId;
    copyObject.isDummyEntry = this.isDummyEntry;
    return copyObject;
  }

  isEmpty() {
    var cell;
    for (cell of this.cells) {
      if (
        cell.value !== undefined &&
        cell.value !== null &&
        cell.value !== "" &&
        isNaN(cell.value) === false &&
        !cell.invalidInputText === true
      ) {
        if (cell.type === cellType.STAR) {
          if (cell.value === 1) {
            return false;
          }
        } else {
          return false;
        }
      } else if (cell.value && cell.value.length > 0 && !cell.invalidInputText === true) {
        return false;
      }
    }
    return true;
  }

  isLinkingInProgress() {
    return (this.isLinking === true && !this.linkingAccountsData === true) || !this.linkingAccountsData === false;
  }

  getLinkedAccountDescription() {
    return GridRowData.linkedAccountDescription({
      linkType: this.linkType,
      linkProviderName: this.linkProviderName,
      linkAccountName: this.linkAccountName,
      linkAccountMask: this.linkAccountMask,
      linkProviderAccountId: this.linkProviderAccountId,
      linkAccountId: this.linkAccountId,
      linkProviderId: this.linkProviderId
    });
  }

  getLinkedAccountRemoveDescription() {
    return GridRowData.linkedAccountRemoveDescription({
      linkType: this.linkType,
      linkProviderName: this.linkProviderName,
      linkAccountName: this.linkAccountName,
      linkAccountMask: this.linkAccountMask,
      linkProviderAccountId: this.linkProviderAccountId,
      linkAccountId: this.linkAccountId
    });
  }

  isUpdatedToday() {
    if (!this.tsModified === true) {
      return false;
    }
    const lastModifiedDate = new Date(this.tsModified * 1000).getDate();
    const currentDate = new Date().getDate();
    return lastModifiedDate === currentDate;
  }

  getIrrDetails() {
    try {
      const details = JSON.parse(this.irr);
      if (!details.all.error === false) {
        return null;
      }
      return details;
    } catch (e) {
      return null;
    }
  }

  getIrr() {
    const irrDetails = this.getIrrDetails();
    if (!irrDetails === true) {
      return null;
    }
    const sanitizedIrr = sanitizeIrr(irrDetails.all.value);
    return Math.abs(sanitizedIrr) > 0 && Math.abs(sanitizedIrr) < 1 ? sanitizedIrr : Math.kuberaFloor(sanitizedIrr);
  }
}

export class GridColumnData {
  constructor(name, isSelectable, isEditable, isButton) {
    this.name = name;
    this.isSelectable = isSelectable;
    this.isEditable = isEditable;
    this.isButton = isButton;
    this.hideTitleIfEmpty = false;
    this.hideTotalForColumn = false;
    this.total = null;
    this.hide = false;
    this.getFooterAccessoryView = (sheetIndex, sectionIndex, cellIndex) => {
      return null;
    };
  }
}

export class GridCellData {
  constructor(type, name, value) {
    this.type = type;
    this.name = name;
    this.value = value;
    this.placeholder = name;
    this.description = null;
    this.secondaryDescription = null;
    this.toolTip = "";
    this.loading = false;
    this.showHint = true;
    this.isEditable = true;
    this.invalidInputText = null;
    this.textAlignment = null;
    this.hasError = false;
    this.isCashflowInvalid = false;
    this.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      return null;
    };
    this.onClick = (e, sheetIndex, sectionIndex, rowIndex, cellIndex) => {};
    this.unfundedCapital = null;
    this.getCellStyles = (rowIndex, cellIndex) => {
      return null;
    };
    this.previousValue = null;
    this.emptyValueIndicator = null;
  }

  static getCellId(gridId, sheetIndex, sectionIndex, rowIndex, cellIndex) {
    return gridId + "," + sheetIndex + "," + sectionIndex + "," + rowIndex + "," + cellIndex;
  }

  static getDataCyId(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    const datacyPrefix = getPrefixForDataCy();
    return datacyPrefix + "H" + sheetIndex + "S" + sectionIndex + "R" + rowIndex + "C" + cellIndex;
  }

  static getSelectedCellIndexes(currentlyActiveItem) {
    if (currentlyActiveItem === null) {
      return null;
    }
    if (currentlyActiveItem.id === undefined) {
      return null;
    }

    const selectedCellIndexes = currentlyActiveItem.id.split(",");
    selectedCellIndexes.shift();
    if (selectedCellIndexes.length !== 4) {
      return null;
    }
    return selectedCellIndexes.map(value => parseInt(value));
  }
}

export class CurrencyCellData extends GridCellData {
  constructor(type, name, value, currency) {
    super(type, name, value);
    this.currency = currency;
    this.multiCurrency = true;
    this.useRateFromExchangeRateDetails = false;
    this.exchangeRateDetails = null;
    this.supportedTickerTypes = [tickerTypes.FIAT];
    this.tickerInfo = null;
    this.pastValueDetails = null;
    this.canShowDayChange = true;
    this.isAssetCustodianForDebt = false;
    this.isDebt = false;

    this.cost = null;
    this.costTickerId = null;
    this.costExchangeRate = null;
    this.enterEditModeOnClick = false;
    this.hideCellFocusHighlight = false;
    this.exchangeRateDate = null;
    this.isInSingleCellMode = false;
    this.textAlignment = "right";
    this.callOnChangeEverytime = false;
    this.previousCurrency = null;
  }

  getTickerId() {
    if (this.value === null || this.value === undefined) {
      return null;
    }
    return getTickerUsingShortName(this.currency).id;
  }

  setExchangeRateDetails(forCurrency, inputRate = null) {
    if (this.value === null || this.value === undefined) {
      this.exchangeRateDetails = null;
      return;
    }

    const forTicker = getTickerUsingShortName(forCurrency);
    var rate = inputRate;
    if (!rate === true) {
      rate = this.getCellExchangeRate(forCurrency);
    }
    this.exchangeRateDetails = getExchangeRateDetails(forTicker.id, rate, this.exchangeRateDate);
  }

  getValueInCurrency(currency, forPastInterval = null, ownership = this.ownership) {
    if (!forPastInterval === false) {
      if (!this.pastValueDetails === true) {
        return 0;
      }
      try {
        const valueDetailsForInterval = JSON.parse(this.pastValueDetails)[forPastInterval];
        if (!valueDetailsForInterval === true) {
          return 0;
        }

        if (valueDetailsForInterval.valueTickerId === getTickerUsingShortName(currency).id) {
          return calcCustodianOwnershipValue(valueDetailsForInterval.value, valueDetailsForInterval.ownership);
        }

        return (
          calcCustodianOwnershipValue(valueDetailsForInterval.value, valueDetailsForInterval.ownership) *
          this.getExchangeRateFromDetailsString(valueDetailsForInterval.valueExchangeRate, currency, forPastInterval)
        );
      } catch (e) {
        return 0;
      }
    }

    return calcCustodianOwnershipValue(this.value, ownership) * this.getCellExchangeRate(currency);
  }

  // @TODO: Remove this function. Cost is only picked from IRR
  // getCostInCurrency(currency) {
  //   if (!this.cost === true || !this.costTickerId === true) {
  //     return 0;
  //   }
  //   return getCustodianCost(this.cost, this.costExchangeRate, getTickerUsingId(this.costTickerId).shortName, currency);
  // }

  getCellExchangeRate(forCurrency) {
    if (this.useRateFromExchangeRateDetails === false) {
      return getExchangeRate(this.currency, forCurrency, false, this.exchangeRateDate);
    }
    return this.getExchangeRateFromDetailsString(this.exchangeRateDetails, forCurrency);
  }

  getExchangeRateFromDetailsString(details, forCurrency, forPastInterval = null) {
    var exchangeRateDetails = null;
    const usePreviousDayExchangeRates = forPastInterval === pastValueInterval.DAY;
    try {
      exchangeRateDetails = JSON.parse(details);
    } catch (e) {}
    if (!exchangeRateDetails === true) {
      return getExchangeRate(this.currency, forCurrency, usePreviousDayExchangeRates, this.exchangeRateDate);
    }

    if (forCurrency === this.currency) {
      return 1;
    }

    const forCurrencyTicker = getTickerUsingShortName(forCurrency);
    if (forCurrencyTicker.id === exchangeRateDetails.tickerId) {
      return exchangeRateDetails.rate;
    } else {
      const detailsTargetTicker = getTickerUsingId(exchangeRateDetails.tickerId);

      // This is to fix a bug where for tickers post searching we were using the conversion
      // rate to USD instead of mapping that to the tickers currency rate
      if (
        !forPastInterval === true &&
        detailsTargetTicker.shortName === this.currency &&
        exchangeRateDetails.rate !== 1
      ) {
        return (1 / exchangeRateDetails.rate) * getExchangeRate("USD", forCurrency);
      }

      return (
        exchangeRateDetails.rate *
        getExchangeRate(detailsTargetTicker.shortName, forCurrency, usePreviousDayExchangeRates)
      );
    }
  }

  getDayChange(inCurrency) {
    if (!this.canShowDayChange === true) {
      return 0;
    }
    if (!this.pastValueDetails === true) {
      return 0;
    }

    const currentValue = this.getValueInCurrency(inCurrency);
    const oldValue =
      this.getValueInCurrency(inCurrency, pastValueInterval.DAY) * (this.isAssetCustodianForDebt ? -1 : 1);
    if (!oldValue === true || !currentValue === true) {
      return 0;
    }
    return Math.kuberaFloor(currentValue) - Math.kuberaFloor(oldValue);
  }
}

export class PercentageCellData extends GridCellData {
  constructor(type, name, value, firstCellIndex, secondCellIndex) {
    super(type, name, value);
    this.firstCellIndex = firstCellIndex;
    this.secondCellIndex = secondCellIndex;
  }
}

export const cellType = {
  TEXT: "text",
  CLICKABLE_TEXT: "clickable_text",
  NUMBER: "number",
  CURRENCY: "currency",
  PERCENTAGE: "percentage",
  OPTIONS: "options",
  HEADER: "header",
  FOOTER_CURRENCY: "footer_currency",
  FOOTER_PERCENTAGE: "footer_percentage",
  ADDNEW_BUTTON: "add_new_button",
  PADDING: "padding",
  BLANKSPACE: "",
  DETAILS: "details",
  STAR: "star",
  LINK_ERROR: "link_error",
  LINKING_INPUT_NEEDED: "link_input_needed",
  LINKING_FAILURE: "linking_failure",
  LOADER: "loader",
  TICKER_ERROR: "ticker_error",
  RECAP_CELL: "recap_cell",
  HEAVY_ICON: "heavy_icon",
  EDITABLE_PERCENTAGE_CELL: "editable_percentage_cell",
  RECOMMENDATION_BADGE: "recommendation_badge",
  RED_DOT: "red_dot"
};

export const getGridDataFromPortfolio = (
  portfolio,
  category,
  currency,
  createSheet,
  createSection,
  createRow,
  currentGridData = null,
  entitiesToUpdate = []
) => {
  const isViewOnlyMode = isAppInViewMode() === true || portfolio.write === 0;
  const isRefresh = !currentGridData === false && !entitiesToUpdate === false;
  var sheets = [];

  const sheetsMap = new Map();
  const sectionsMap = new Map();

  const details = portfolio.details;
  details.sheet.forEach(item => {
    sheetsMap.set(item.id, {
      item,
      sections: new Map(),
      tempIrr: item.irr
    });

    item.irr = null;
  });

  details.section.forEach(item => {
    sheetsMap.get(item.sheetId).sections.set(item.id, {
      item,
      rows: new Map(),
      rowsArr: [],
      emptyRowsCount: 0,
      showCost: true,
      tempIrr: item.irr
    });

    item.irr = null;
    sectionsMap.set(item.id, item.sheetId);
  });

  details.custodian.forEach(item => {
    const custodian = item;
    if (custodian.hidden === 1) return;

    // Only insert new row if its not being refreshed
    // or the row is newly added
    var currentRow = null;
    if (isRefresh === true && entitiesToUpdate.includes(custodian.id) === false) {
      currentRow = currentGridData.getRow(custodian.id);
    }
    if (!currentRow === true) {
      currentRow = createRow(custodian);
    }

    currentRow.parentId = custodian.parentId;
    currentRow.tsModified = custodian.tsModified;
    if (custodian.past === undefined || custodian.past === null) {
      currentRow.isUpdated = true;
    } else {
      currentRow.isUpdated = custodian.past === 0;
    }
    currentRow.wasRowEverComplete = custodian.isCompleted === 1;
    currentRow.autoAddedForEmptySections = custodian.autoAddedForEmptySections;
    currentRow.irr = custodian.irr;

    const currentSheet = sheetsMap.get(sectionsMap.get(item.sectionId));
    const currentSection = currentSheet?.sections.get(item.sectionId);

    if (currentRow.isEmpty() && currentSection) {
      currentSection.emptyRowsCount++;
    }

    if (
      currentRow.isComplete() === true &&
      ((currentRow.irrType === irrTypes.CASHFLOW &&
        getValueOfPropFromStringifiedJSON(currentRow.irr, "error") === `""`) ||
        (currentRow.irrType === irrTypes.HOLDING &&
          getValueOfPropFromStringifiedJSON(currentRow.irr, "cashInType") !== `"cost"`)) &&
      currentSection
    ) {
      currentSection.showCost = false;
    }

    if (currentRow.isComplete() === true && currentRow.irr) {
      if (currentSheet) {
        currentSheet.item.irr = currentSheet.tempIrr;
      }
      if (currentSection) {
        currentSection.item.irr = currentSection.tempIrr;
      }
    }

    if (currentSection) {
      currentSection.rows.set(currentRow.id, currentRow);
      currentSection.rowsArr.push(currentRow);
    }
  });

  for (let eachSheet of sheetsMap.values()) {
    const sheet = eachSheet.item;

    if (sheet.category !== category) continue;
    var sections = [];
    let sectionIndex = 0;
    let sheetCanShowCostBasis = true;
    for (let eachSection of eachSheet.sections.values()) {
      const section = eachSection.item;

      var rows = eachSection.rowsArr;
      const currentSection = createSection(sectionIndex, section, rows);
      currentSection.isCollapsed = section.expanded === 0;

      // If there are more than three empty rows in a section remove the ones that were auto added
      // to prevent the section from being empty
      if (currentSection.emptyRowsCount > 3) {
        currentSection.rows = currentSection.rows.filter(row => !row.autoAddedForEmptySections === true);
      }

      currentSection.showCost = eachSection.showCost;
      if (!currentSection.showCost) {
        sheetCanShowCostBasis = false;
      }
      if (currentSection.isEmpty()) {
        currentSection.irr = null;
      }
      if (isViewOnlyMode === false || currentSection.isEmpty() === false) {
        sections.push(currentSection);
      }
    }
    const currentSheet = createSheet(sheet, sections);
    currentSheet.updateFrequency =
      GridSheetUpdateFrequency[!sheet.updateFrequency === true ? 0 : sheet.updateFrequency];

    currentSheet.showCost = sheetCanShowCostBasis;

    if (currentSheet.isEmpty()) {
      currentSheet.irr = null;
    }
    if (isViewOnlyMode === false || currentSheet.isEmpty() === false) {
      sheets.push(currentSheet);
    }
  }

  const gridData = new GridData(currency, sheets);
  gridData.portfolioNetWorthData = details.networth;
  gridData.changeData = details.changeData;
  gridData.category = category;

  const portfolios = portfoliosSelector(store.getState());
  gridData.portfoliosCount = !portfolios === true ? 0 : portfolios.length;
  return gridData;
};

export const filterDataFromPortfolioDetails = (
  details,
  custodianFilter = () => true,
  sectionFilter = () => true,
  sheetFilter = () => true
) => {
  const sheets = [];
  const sections = [];
  const rows = [];

  for (const sheet of details.sheet) {
    if (!sheetFilter(sheet)) {
      continue;
    }

    sheets.push(sheet);
    for (const [, section] of details.section.entries()) {
      if (section.sheetId !== sheet.id || !sectionFilter(section)) {
        continue;
      }

      sections.push(section);
      for (var custodian of details.custodian) {
        if (custodian.sectionId !== section.id || custodian.hidden === 1 || custodian.isLinking === true) {
          continue;
        }

        if (sheet.category === category.DEBT && isAssetCustodian(custodian.linkContainer, custodian.linkType)) {
          custodian = { ...custodian };
          custodian.value = -custodian.value;
        }
        if (custodianFilter(custodian)) {
          rows.push(custodian);
        }
      }
    }
  }

  return {
    sheets,
    sections,
    rows
  };
};

export const getEmptyRow = (sortKey, currency) => {
  const detailsCell = new GridCellData(cellType.DETAILS, "", null);
  detailsCell.showHint = false;
  detailsCell.toolTip = i18n.t("gridCell.detailsButtonToolTip");
  const nameCell = new GridCellData(cellType.TEXT, category.ASSET, null);
  const percentageCell = new PercentageCellData(cellType.PERCENTAGE, "Percentage", null, 3, 4);
  const costCell = new CurrencyCellData(cellType.CURRENCY, "Cost", null, currency);
  costCell.useRateFromExchangeRateDetails = true;
  costCell.supportedTickerTypes = [tickerTypes.FIAT, tickerTypes.CRYPTO];
  const valueCell = new CurrencyCellData(cellType.CURRENCY, "Value", null, currency);
  valueCell.supportedTickerTypes = [
    tickerTypes.FIAT,
    tickerTypes.CRYPTO,
    tickerTypes.STOCK,
    tickerTypes.FUND,
    tickerTypes.BOND,
    tickerTypes.DERIVATIVE,
    tickerTypes.INDEX
  ];
  const padCell = new GridCellData(cellType.PADDING, "", null);
  padCell.toolTip = i18n.t("gridCell.starButtonToolTip");
  const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
  optionsCell.toolTip = i18n.t("gridCell.optionsButtonToolTip");
  const cells = [padCell, nameCell, percentageCell, costCell, valueCell, detailsCell, optionsCell];
  const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 1, false, () => {
    const name = cells[1].value;
    const value = cells[4].value;

    if (name && name.length > 0 && (value !== null && value !== undefined)) {
      return true;
    }
    return false;
  });
  rowData.isDragEnabled = true;
  rowData.defaultCellIndexToSelect = 1;
  return rowData;
};

export const getEmptySection = (forIndex, sortKey, currency, rows) => {
  const padColumn = new GridColumnData(null, true, false, true);
  const nameColumn = new GridColumnData("", true, true, false);
  const percentageColumn = new GridColumnData(null, false, false, false);
  const costColumn = new GridColumnData("Cost", true, true, false);
  const valueColumn = new GridColumnData("Value", true, true, false);
  const detailsColumn = new GridColumnData(null, true, false, true);
  const optionsColumn = new GridColumnData(null, true, false, true);

  const sectionData = new GridSectionData(
    getUuid(),
    sortKey,
    "Section " + (forIndex + 1),
    rows || [getEmptyRow("1"), getEmptyRow("2"), getEmptyRow("3")],
    [padColumn, nameColumn, percentageColumn, costColumn, valueColumn, detailsColumn, optionsColumn],
    3,
    4,
    false
  );

  return sectionData;
};

export const getEmptySheet = (name, sortKey, currency, rows) => {
  return new GridSheetData(getUuid(), sortKey, name, [getEmptySection(0, "1", currency, rows)]);
};

export const arrangeSortKeysForIds = array => {
  let newSortKey = null;
  const hashMap = {};
  const arrayOfSortedList = array.map(item => {
    newSortKey = newSortKey ? getSortKeyBetween(newSortKey, null) : "0";

    hashMap[item.id] = newSortKey;
    return {
      id: item.id,
      sortKey: newSortKey
    };
  });

  return {
    hashMap,
    list: arrayOfSortedList
  };
};

const createReportSectionRows = (
  row,
  selectedChartOptions,
  selectedChartType,
  isPercentageChangeShown,
  currency,
  portfolioCurrency,
  noOfColumns
) => {
  let cells = [];
  const isAllValuesZero = row.values.slice(0, noOfColumns).every(data => data.value === 0);
  const isCurrentDayValueZero = row.values[0].value === 0;
  const expandCollapseIconCell = new GridCellData(cellType.RECAP_CELL, "", null);
  expandCollapseIconCell.isExpandCollapseIconCell = true;
  expandCollapseIconCell.isDisabled =
    (isAllValuesZero && row.type === "header") || selectedChartOptions === recapChartOptions.CASH_ON_HAND.id;
  expandCollapseIconCell.selectedChartOptions = selectedChartOptions;
  expandCollapseIconCell.isArchived = row.isArchived;
  const chartsCell = new GridCellData(cellType.RECAP_CELL, "", null);
  chartsCell.isChartsCell = true;
  chartsCell.isDisabled = isAllValuesZero || isCurrentDayValueZero || row.isArchived;
  cells.push(expandCollapseIconCell, chartsCell);
  const nameCell = new GridCellData(cellType.RECAP_CELL, "value", row.name);
  nameCell.isTextCell = true;
  nameCell.selectedChartOptions = selectedChartOptions;
  nameCell.isArchived = row.isArchived;
  nameCell.showDescription = row.isUsFund;
  nameCell.percentage = row.percentage;
  nameCell.totalValue = row.totalValue;
  nameCell.currency = currency;
  //new GridCellData(cellType.TEXT, null, custodian.name);
  cells.push(nameCell);
  for (let cell of row.values) {
    let value = cell.value;

    let recapCell = new CurrencyCellData(
      cellType.RECAP_CELL,
      "Value",
      selectedChartType === recapChartTypes.PERCENTAGE_ALLOCATION
        ? value
        : convertCurrency(value, currency, portfolioCurrency),
      portfolioCurrency
    );
    recapCell.isPercentageChangeShown = isPercentageChangeShown;
    recapCell.selectedChartType = selectedChartType;
    recapCell.selectedChartOptions = selectedChartOptions;
    recapCell.actualValue = cell.actualValue;
    cells.push(recapCell);
  }
  const currentRow = new GridRowData(
    getUuid(),
    1,
    "entry-id-" + Math.random(),
    cells,
    null,
    false,
    () => true,
    row.type === "header"
  );
  currentRow.isColumnsInDecreasingOrder = true;
  //currentRow.shouldShow = true;
  return currentRow;
  // currentRow.category = rowIndex === 0 && sectionKey !== RECAP_CATEGORY_TYPE_NETWORTH ? row.type : row.category;
  // rows.push(currentRow);
};

const checkIfArchivedIsEmpty = (archivedData, noOfColumns) => {
  if (archivedData.sheets.length === 0) {
    return true;
  } else {
    return (
      archivedData.sheets[0] &&
      archivedData.sheets[0].sections[0] &&
      archivedData.sheets[0].sections[0].rows.length === 0
    );
  }
};

export const getGridDataForRecap = (
  recapData,
  portfolioCurrency,
  selectedChartOptions,
  currency,
  selectedChartType,
  columns,
  isPercentageChangeShown,
  selectedSectionName,
  noOfColumns,
  portfolioId,
  selectedSectionId,
  selectedSheetId,
  category,
  selectedRowCategory
) => {
  let sheets = [];
  const sections = [];
  const recapReportPreferences = JSON.parse(localStorage.getItem("recap_report_preferences"));
  if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
    for (let [key, category] of Object.entries(recapData)) {
      const rows = [];
      if (key === RECAP_CATEGORY_TYPE_NETWORTH) {
        continue;
      } else {
        //create sheet row
        if (
          (key === RECAP_CATEGORY_TYPE_ARCHIVED_ASSETS ||
            key === RECAP_CATEGORY_TYPE_ARCHIVED_DEBTS ||
            key === RECAP_CATEGORY_TYPE_DEBT) &&
          checkIfArchivedIsEmpty(category, noOfColumns)
        ) {
          continue;
        }
        const categoryRow = createReportSectionRows(
          category,
          selectedChartOptions,
          selectedChartType,
          isPercentageChangeShown,
          currency,
          portfolioCurrency,
          noOfColumns
        );
        categoryRow.category = "categoryHeader";
        categoryRow.shouldShow = true;
        categoryRow.isCollapsed =
          key === RECAP_CATEGORY_TYPE_ARCHIVED_ASSETS || key === RECAP_CATEGORY_TYPE_ARCHIVED_DEBTS
            ? recapReportPreferences &&
              recapReportPreferences[portfolioId] &&
              recapReportPreferences[portfolioId][selectedChartOptions] &&
              recapReportPreferences[portfolioId][selectedChartOptions][`${category.name}`] !== undefined
              ? recapReportPreferences[portfolioId][selectedChartOptions][`${category.name}`].isCollapsed
              : true
            : true;
        categoryRow.isArchivedCategory =
          key === RECAP_CATEGORY_TYPE_ARCHIVED_ASSETS || key === RECAP_CATEGORY_TYPE_ARCHIVED_DEBTS ? true : false;
        rows.push(categoryRow);
        if (category !== undefined) {
          for (let sheet of category.sheets) {
            let sheetHeaderRow;
            //create section row
            if (key !== RECAP_CATEGORY_TYPE_ARCHIVED_ASSETS && key !== RECAP_CATEGORY_TYPE_ARCHIVED_DEBTS) {
              sheetHeaderRow = createReportSectionRows(
                sheet,
                selectedChartOptions,
                selectedChartType,
                isPercentageChangeShown,
                currency,
                portfolioCurrency,
                noOfColumns
              );
              sheetHeaderRow.sheetId = sheet.id;
              sheetHeaderRow.category = "sheetHeader";
              sheetHeaderRow.categoryKey = key;
              sheetHeaderRow.isCollapsed = selectedSheetId
                ? !(sheet.id === selectedSheetId)
                : recapReportPreferences &&
                  recapReportPreferences[portfolioId] &&
                  recapReportPreferences[portfolioId][selectedChartOptions] &&
                  recapReportPreferences[portfolioId][selectedChartOptions][`${key}.${sheet.id}`] !== undefined
                ? recapReportPreferences[portfolioId][selectedChartOptions][`${key}.${sheet.id}`].isCollapsed
                : true;
              sheetHeaderRow.shouldShow = true;
              rows.push(sheetHeaderRow);
            }
            for (let section of sheet.sections) {
              let sectionHeaderRow;
              // add section header only if there are more than one sections. If there is only one section, it means, user has entered in default section which has no name yet
              if (sheet.sections.length > 1) {
                sectionHeaderRow = createReportSectionRows(
                  section,
                  selectedChartOptions,
                  selectedChartType,
                  isPercentageChangeShown,
                  currency,
                  portfolioCurrency,
                  noOfColumns
                );
                sectionHeaderRow.sheetId = sheet.id;
                sectionHeaderRow.sectionId = section.sectionId;
                sectionHeaderRow.category = "sectionHeader";
                sectionHeaderRow.sheetName = sheet.name;
                sectionHeaderRow.categoryKey = key;
                sectionHeaderRow.isCollapsed =
                  selectedRowCategory === "sheetHeader"
                    ? !(sheet.id === selectedSheetId)
                    : selectedSectionId
                    ? !(sheet.id === selectedSheetId && section.sectionId === selectedSectionId)
                    : recapReportPreferences &&
                      recapReportPreferences[portfolioId] &&
                      recapReportPreferences[portfolioId][selectedChartOptions] &&
                      recapReportPreferences[portfolioId][selectedChartOptions][
                        `${key}.${sheet.id}.${section.sectionId}`
                      ] !== undefined
                    ? recapReportPreferences[portfolioId][selectedChartOptions][
                        `${key}.${sheet.id}.${section.sectionId}`
                      ].isCollapsed
                    : true;
                sectionHeaderRow.shouldShow = !sheetHeaderRow.isCollapsed;
                rows.push(sectionHeaderRow);
              }
              for (let row of section.rows) {
                let currentRow = createReportSectionRows(
                  row,
                  selectedChartOptions,
                  selectedChartType,
                  isPercentageChangeShown,
                  currency,
                  portfolioCurrency,
                  noOfColumns
                );
                if (row.id) {
                  currentRow.id = row.id;
                }
                currentRow.sheetId = sheet.id;
                currentRow.category = row.category;
                currentRow.sectionId = section.sectionId;
                currentRow.categoryKey = key;
                currentRow.shouldShow =
                  sheet.sections.length > 1
                    ? sheetHeaderRow.isCollapsed
                      ? false
                      : !sectionHeaderRow.isCollapsed
                    : key === RECAP_CATEGORY_TYPE_ARCHIVED_ASSETS || key === RECAP_CATEGORY_TYPE_ARCHIVED_DEBTS
                    ? !categoryRow.isCollapsed
                    : !sheetHeaderRow.isCollapsed;
                rows.push(currentRow);
              }
            }
          }
        }
      }
      const currentSection = new GridSectionData(getUuid(), 1, key);
      currentSection.rows = rows;
      currentSection.columns = columns;
      currentSection.showHeader = false;
      currentSection.showFooter = false;
      currentSection.forceShowTitle = false;
      currentSection.isRecapSection = true;
      currentSection.isSheetsAndSectionsReport = true;
      sections.push(currentSection);
    }
  } else {
    for (let [index, section] of Object.entries(recapData)) {
      if (index === RECAP_CATEGORY_TYPE_NETWORTH && selectedChartOptions !== recapChartOptions.NETWORTH.id) {
        continue;
      }
      section.id = getUuid();
      section.indexKey = index;
      const shouldSectionsBeCollapsed =
        recapReportPreferences &&
        recapReportPreferences[portfolioId] &&
        recapReportPreferences[portfolioId][selectedChartOptions] &&
        recapReportPreferences[portfolioId][selectedChartOptions][index] !== undefined
          ? recapReportPreferences[portfolioId][selectedChartOptions][index].isCollapsed
          : selectedChartOptions === recapChartOptions.ASSET_CLASSES.id ||
            selectedChartOptions === recapChartOptions.ASSETS_AND_CURRENCY.id ||
            selectedChartOptions === recapChartOptions.STOCKS_AND_MARKETCAP.id ||
            selectedChartOptions === recapChartOptions.STOCKS_AND_GEOGRAPHY.id ||
            selectedChartOptions === recapChartOptions.STOCKS_AND_SECTOR.id ||
            selectedChartOptions === recapChartOptions.CRYPTO.id ||
            selectedChartOptions === recapChartOptions.INVESTABLE.id ||
            selectedChartOptions === recapChartOptions.INVESTABLE_WITHOUT_CASH.id;
      if (section !== undefined && section.length) {
        const rows = section;
        //selectedChartType === recapChartTypes.TOTALS ? section.totals : section.totals;
        const gridRows = [];
        for (const [rowIndex, row] of rows.entries()) {
          const currentRow = createReportSectionRows(
            row,
            selectedChartOptions,
            selectedChartType,
            isPercentageChangeShown,
            currency,
            portfolioCurrency,
            noOfColumns
          );

          if (row.id) {
            currentRow.id = row.id;
          }
          currentRow.isColumnsInDecreasingOrder = true;
          currentRow.category = rowIndex === 0 && index !== RECAP_CATEGORY_TYPE_NETWORTH ? row.type : row.category;
          currentRow.clubbingKey = row.clubbingKey;
          gridRows.push(currentRow);
        }

        const currentSection = new GridSectionData(
          getUuid(),
          1,
          section.indexKey,
          null,
          null,
          null,
          null,
          selectedSectionName && selectedSectionName !== "null"
            ? getCollapsedStateForSection(selectedSectionName, index)
            : shouldSectionsBeCollapsed
        );
        currentSection.rows = gridRows;
        currentSection.columns = columns;
        currentSection.showHeader = false;
        currentSection.showFooter = false;
        currentSection.forceShowTitle = false;
        currentSection.isRecapSection = true;
        sections.push(currentSection);
      } else {
        continue;
      }
    }
  }
  const currentSheet = new GridSheetData(getUuid(), 1, null, sections);
  currentSheet.sections = sections;
  currentSheet.showHeader = false;
  currentSheet.columnSortKey = null;
  currentSheet.columnSortOrder = null;
  sheets.push(currentSheet);
  const recapGridData = new GridData(portfolioCurrency, sheets);
  recapGridData.isEditable = false;
  recapGridData.forceShowSheetsTitles = false;
  return recapGridData;
};

const createDummyReportSectionRows = (row, selectedChartOptions, selectedChartType) => {
  let cells = [];
  const expandCollapseIconCell = new GridCellData(cellType.RECAP_CELL, "", null);
  expandCollapseIconCell.isExpandCollapseIconCell = true;
  expandCollapseIconCell.isDisabled = true;
  expandCollapseIconCell.selectedChartOptions = selectedChartOptions;
  const chartsCell = new GridCellData(cellType.RECAP_CELL, "", null);
  chartsCell.isChartsCell = true;
  chartsCell.isDisabled = true;
  cells.push(expandCollapseIconCell, chartsCell);
  const nameCell = new GridCellData(cellType.RECAP_CELL, "value", row.name);
  nameCell.isTextCell = true;
  nameCell.selectedChartOptions = selectedChartOptions;
  nameCell.isDummyCell = row.type !== "header";
  cells.push(nameCell);
  for (let cell of row.values) {
    let value = cell.value;

    let recapCell = new CurrencyCellData(cellType.RECAP_CELL, "Value", value);
    recapCell.isDummyCell = true;
    recapCell.selectedChartType = selectedChartType;
    recapCell.selectedChartOptions = selectedChartOptions;
    cells.push(recapCell);
  }
  const currentRow = new GridRowData(
    getUuid(),
    1,
    "entry-id-" + Math.random(),
    cells,
    null,
    false,
    () => true,
    row.type === "header"
  );
  currentRow.isColumnsInDecreasingOrder = true;
  return currentRow;
};

const getDummyRowNameForRecapSkeletonLoader = selectedChartOptions => {
  switch (selectedChartOptions) {
    case recapChartOptions.NETWORTH.id:
      return "Net Worth";
    case recapChartOptions.SHEETS_AND_SECTIONS.id:
    case recapChartOptions.ASSET_CLASSES.id:
    case recapChartOptions.ASSETS_AND_CURRENCY.id:
      return "Assets";
    case recapChartOptions.STOCKS_AND_MARKETCAP.id:
    case recapChartOptions.STOCKS_AND_GEOGRAPHY.id:
    case recapChartOptions.STOCKS_AND_SECTOR.id:
      return "Stocks";
    case recapChartOptions.CRYPTO.id:
      return "Cryptos";
    case recapChartOptions.INVESTABLE.id:
      return "Investable Assets";
    case recapChartOptions.INVESTABLE_WITHOUT_CASH.id:
      return "Investable Assets ex Cash";
    case recapChartOptions.CASH_ON_HAND.id:
      return "Cash";
    case recapChartOptions.BROKERAGES.id:
      return "Brokerages";
    default:
      return "Assets";
  }
};

const getDummyRowsForRecapSkeletonLoader = (selectedChartOptions, selectedChartType, sectionIndex, noOfColumns) => {
  const gridRows = [];
  for (let rowIndex = 0; rowIndex < 8; rowIndex++) {
    let dummyRow;
    const dummyRowValues = new Array(noOfColumns).fill({ value: 1000 });
    if (rowIndex === 0 && sectionIndex === 0) {
      dummyRow = {
        name: getDummyRowNameForRecapSkeletonLoader(selectedChartOptions),
        values: dummyRowValues
      };
      dummyRow.type = "header";
    } else {
      dummyRow = { name: "", values: dummyRowValues };
    }
    const currentRow = createDummyReportSectionRows(dummyRow, selectedChartOptions, selectedChartType);
    currentRow.isColumnsInDecreasingOrder = true;
    gridRows.push(currentRow);
  }
  return gridRows;
};

export const getGridDataForRecapLoader = (
  selectedChartOptions,
  selectedChartTimeRange,
  selectedChartType,
  columns,
  noOfColumns
) => {
  let sheets = [];
  const sections = [];
  for (let sectionIndex = 0; sectionIndex < 2; sectionIndex++) {
    const gridRows = getDummyRowsForRecapSkeletonLoader(
      selectedChartOptions,
      selectedChartType,
      sectionIndex,
      noOfColumns
    );
    const currentSection = new GridSectionData(getUuid(), 1);
    currentSection.rows = gridRows;
    currentSection.columns = columns;
    currentSection.showHeader = false;
    currentSection.showFooter = false;
    currentSection.forceShowTitle = false;
    currentSection.isRecapSection = true;
    sections.push(currentSection);
  }
  const currentSheet = new GridSheetData(getUuid(), 1, null, sections);
  currentSheet.sections = sections;
  currentSheet.showHeader = false;
  currentSheet.columnSortKey = null;
  currentSheet.columnSortOrder = null;
  sheets.push(currentSheet);
  const recapGridData = new GridData("INR", sheets);
  recapGridData.isEditable = false;
  recapGridData.forceShowSheetsTitles = false;
  return recapGridData;
};

const getCollapsedStateForSection = (selectedSectionName, sectionKey) => {
  if (selectedSectionName === "Miscellaneous") {
    return sectionKey !== "Others";
  } else if (selectedSectionName === "Non-US Funds") {
    return sectionKey !== "Funds";
  } else {
    return selectedSectionName !== sectionKey;
  }
};
export const getGridDataFromReportData = (
  reportId,
  reportData,
  portfolio,
  tabName,
  getAccessoryViewForNameCell,
  getAccessoryViewForRecommendationCell
) => {
  const portfolioCurrency = portfolio.currency;

  const sheets = [];
  const sections = [];
  const rows = [];
  let recommendationCount = 0;
  var isTargetValueSetForAnyRow = false;
  for (const content of reportData && reportData.contents) {
    const actualPercentageValue = getPercentageValue(content.value, reportData.total, false, 2);
    const actualPercentage = actualPercentageValue === 0 && content.value !== 0 ? 0.01 : actualPercentageValue;
    const showApproximateSymbol = actualPercentageValue === 0 && content.value !== 0;
    const storedTargetPrecentage = reportTargetPercentageSelector(
      store.getState(),
      reportId,
      tabName,
      content.id || content.name
    );
    var targetPercentage = null;
    var hasUserNotSetATargetValue = true;
    if (storedTargetPrecentage !== null && storedTargetPrecentage !== undefined && storedTargetPrecentage !== "") {
      targetPercentage = storedTargetPrecentage;
      if (actualPercentage !== targetPercentage) {
        hasUserNotSetATargetValue = false;
        isTargetValueSetForAnyRow = true;
        recommendationCount++;
      }
    } else {
      targetPercentage = actualPercentage;
    }

    const recommendation = getRecommendationForReports(
      reportData.total,
      content.value,
      actualPercentage,
      targetPercentage,
      portfolioCurrency
    );
    const nameCell = new GridCellData(cellType.TEXT, "asset", content.name);
    nameCell.accessoryView = getAccessoryViewForNameCell(
      content.name,
      content.type,
      content.sectionId,
      content.sheetId,
      content.category
    );
    const valueCell = new CurrencyCellData(cellType.CURRENCY, "value", content.value, portfolioCurrency);
    valueCell.isReportGridCell = true;
    valueCell.textAlignment = "right";
    valueCell.width = "85px";
    const actualCell = new GridCellData(cellType.EDITABLE_PERCENTAGE_CELL, "actual", actualPercentage);
    actualCell.shouldShowApproximateSymbol = showApproximateSymbol;
    actualCell.width = "50px";
    const targetCell = new GridCellData(cellType.EDITABLE_PERCENTAGE_CELL, "target", targetPercentage);
    targetCell.width = "50px";
    targetCell.hasUserNotSetATargetValue = hasUserNotSetATargetValue;
    const heavyIconCell = new GridCellData(cellType.HEAVY_ICON);
    heavyIconCell.actualValue = actualPercentage;
    heavyIconCell.targetValue = targetPercentage;
    const recommendationCell = new GridCellData(cellType.TEXT, "to rebalance", recommendation);
    recommendationCell.width = "90px";
    recommendationCell.textAlignment = "left";
    recommendationCell.showDottedLineIfEmpty = true;
    const recommendationBadgeCell = new GridCellData(cellType.RECOMMENDATION_BADGE);
    recommendationBadgeCell.actualValue = actualPercentage;
    recommendationBadgeCell.targetValue = targetPercentage;
    let cells = [
      nameCell,
      valueCell,
      actualCell,
      heavyIconCell,
      targetCell,
      recommendationCell,
      recommendationBadgeCell
    ];
    const currentRow = new GridRowData(getUuid(), 0, "entry-id-" + Math.random(), cells, 0, false, () => true);
    currentRow.type = content.type;
    if (!content.id === false) {
      currentRow.id = content.id;
      currentRow.clubbingKey = "id";
    }
    rows.push(currentRow);
  }
  const nameColumn = new GridColumnData("", true, false, false);
  const valueColumn = new GridColumnData("value", true, false, false);
  valueColumn.width = "85px";
  const actualColumn = new GridColumnData("actual", true, false, false);
  actualColumn.width = "50px";
  const targetColumn = new GridColumnData("target", true, isAppInViewMode() === false && portfolio.write !== 0, false);
  targetColumn.width = "50px";
  const recommendationColumn = new GridColumnData("to rebalance", true, false, false);
  recommendationColumn.width = "90px";
  recommendationColumn.isRecommendationColumn = true;
  recommendationColumn.recommendationCount = recommendationCount;
  const heavyIconColumn = new GridColumnData(null, false, false, true);
  const recommendationBadgeColumn = new GridColumnData(null, false, false, true);
  const columns = [
    nameColumn,
    valueColumn,
    actualColumn,
    heavyIconColumn,
    targetColumn,
    recommendationColumn,
    recommendationBadgeColumn
  ];

  var targetTotal = 0;
  var setTargetInvalidError = false;
  if (isTargetValueSetForAnyRow) {
    for (const row of rows) {
      if (row.cells[4].hasUserNotSetATargetValue) {
        row.cells[4].value = "";
      } else if (isNaN(parseFloat(row.cells[4].value)) === false) {
        targetTotal = targetTotal + parseFloat(row.cells[4].value);
        if (targetTotal > 100) {
          setTargetInvalidError = true;
        }
      }
      row.cells[4].hasError = setTargetInvalidError;
    }
  }

  const currentSection = new GridSectionData(getUuid(), 1);
  currentSection.rows = rows;
  currentSection.columns = columns;
  currentSection.showHeader = true;
  currentSection.showFooter = false;
  currentSection.forceShowTitle = false;
  currentSection.isRecapSection = false;
  sections.push(currentSection);

  const currentSheet = new GridSheetData(getUuid(), 1, null, sections);
  currentSheet.sections = sections;
  currentSheet.showHeader = false;
  currentSheet.columnSortKey = null;
  currentSheet.columnSortOrder = null;
  sheets.push(currentSheet);

  const reportsgridData = new GridData(portfolioCurrency, sheets);
  reportsgridData.forceShowSheetsTitles = false;
  return reportsgridData;
};

export const getCollapsedRowsForRecapSection = (rows, isSheetsAndSectionsReport, rowIndexClickedOnRecapReports) => {
  if (!isSheetsAndSectionsReport) {
    return rows.slice(0, 1);
  } else {
    if (rowIndexClickedOnRecapReports || rowIndexClickedOnRecapReports === 0) {
      const clickedRow = rows[rowIndexClickedOnRecapReports];
      const clickedRowId = clickedRow.id;
      if (clickedRow.category === "sheetHeader") {
        if (clickedRow.isCollapsed) {
          const sectionsInSelectedSheet = rows.filter(
            row => row.category === "sectionHeader" && row.sheetId === clickedRow.sheetId
          );
          return rows.map(row => {
            if (row.id === clickedRowId) {
              row.isCollapsed = false;
            }
            if (sectionsInSelectedSheet.length > 0) {
              if (row.sheetId === clickedRow.sheetId) {
                if (row.category == "sheetHeader" || row.category === "sectionHeader") {
                  row.shouldShow = true;
                } else {
                  const sectionOfSelectedRow = sectionsInSelectedSheet.find(
                    section => section.sectionId === row.sectionId
                  );
                  if (sectionOfSelectedRow && sectionOfSelectedRow.isCollapsed) {
                    row.shouldShow = false;
                  } else {
                    row.shouldShow = true;
                  }
                }
              }
            } else {
              if (row.sheetId === clickedRow.sheetId && row.category !== "sheetHeader") {
                row.shouldShow = true;
              }
            }

            return row;
          });
        } else {
          return rows.map(row => {
            if (row.id === clickedRowId) {
              row.isCollapsed = true;
            }
            if (row.sheetId === clickedRow.sheetId && row.category !== "sheetHeader") {
              row.shouldShow = false;
            }
            return row;
          });
        }
      } else if (clickedRow.category === "sectionHeader") {
        if (clickedRow.isCollapsed) {
          return rows.map(row => {
            if (row.id === clickedRowId || row.sectionId === clickedRow.sectionId) {
              row.isCollapsed = false;
            }
            if (row.sectionId === clickedRow.sectionId && row.category !== "sectionHeader") {
              row.shouldShow = true;
            }
            return row;
          });
        } else {
          return rows.map(row => {
            if (row.id === clickedRowId) {
              row.isCollapsed = true;
            }
            if (row.sectionId === clickedRow.sectionId && row.category !== "sectionHeader") {
              row.shouldShow = false;
            }
            return row;
          });
        }
      } else if (clickedRow.category === "categoryHeader" && clickedRow.isArchivedCategory) {
        if (clickedRow.isCollapsed) {
          return rows.map(row => {
            if (row.id === clickedRowId) {
              row.isCollapsed = false;
            }
            row.shouldShow = true;
            return row;
          });
        } else {
          return rows.map(row => {
            if (row.id === clickedRowId) {
              row.isCollapsed = true;
            } else {
              row.shouldShow = false;
            }
            return row;
          });
        }
      }
    }
    return rows;
  }
};

export const getRecommendationCountFromGridData = rows => {
  let recommendationCount = 0;
  for (const row of rows) {
    const actualValue = row.cells[2].value;
    const targetValue = parseNumberStringToFloat(row.cells[4].value);
    if (targetValue <= actualValue - 5 || targetValue >= actualValue + 5) {
      recommendationCount++;
    }
  }
  return recommendationCount;
};

export const HEIGHT_OF_ASSET_GRID_ROW = 40;
