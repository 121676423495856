import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  capitalizeStringWithSpaces,
  uploadAiImportDocument,
  currentPortfolioSelector,
  getAiImportExtractedData,
  sheetSelector,
  userPreferencesSelector,
  aiSupportedFileTypesSelector,
  rescanAiImportDocument,
  AI_DOCUMENT_IMPORT_PAYWALL_ACTION,
  showBlackPaywallIfQualifies,
  apiErrorCodes
} from "@kubera/common";
import { withRouter } from "@kubera/common";
import { ReactComponent as AiDocumentImportIcon } from "assets/images/ai_document_import.svg";
import { ReactComponent as AiDocumentImportErrorIcon } from "assets/images/ai_document_import_error.svg";
import DragDropInput from "components/inputs/DragDropInput";
import FileUploadInput from "components/inputs/FileUploadInput";
import SecondaryButton from "components/button/SecondaryButton";
import FancyLoader from "components/loader/FancyLoader";
import { getFileNameWithoutExtension } from "utilities/FileUtils";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { hashParams, modalValues } from "routes";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AiCustodianImportComponent from "./AiCustodianImportComponent";
import AiCashflowImportComponent from "./AiCashflowImportComponent";
import AiFundScheduleImportComponent from "./AiFundScheduleImportComponent";

const importTabs = {
  CUSTODIAN: "CUSTODIAN",
  CASHFLOW: "CASHFLOW",
  CAPITAL_CALL: "CAPITAL_CALL",
  DISTRIBUTION: "DISTRIBUTION"
};

const FILE_UNSUPPORTED_ERROR = "FILE_UNSUPPORTED_ERROR";

const MAX_FILE_SIZE = 1024 * 1024 * 25;

const ImportDialog = styled(Dialog)`
  position: relative;
  width: 1030px;
  min-height: 600px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  margin: 35px
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Title = styled.div`
  max-width: 666px;
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ErrorPreview = styled.div`
  width: 666px;
  margin-top: 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0%;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-offset: Auto;
  text-decoration-thickness: Auto;
  text-decoration-skip-ink: auto;
  color: #0074fc;
  cursor: pointer;
`;

const Preview = styled.div`
  margin-left: 5px;
  margin-bottom: 6px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13.31px;
  letter-spacing: 0%;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-offset: Auto;
  text-decoration-thickness: Auto;
  text-decoration-skip-ink: auto;
  color: #0074fc;
  text-transform: uppercase;
  cursor: pointer;
`;

const Description = styled.div`
  max-width: 650px;
  flex: 1;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const AiDocumentImportIconComponent = styled(AiDocumentImportIcon)`
  position: absolute;
  right: 25px;
  top: 25px;
  width: 98px;
  height: 98px;
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const AiDocumentImportErrorIconComponent = styled(AiDocumentImportErrorIcon)`
  position: absolute;
  right: 25px;
  top: 25px;
  width: 98px;
  height: 98px;
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const DocumentUploadContainer = styled(DragDropInput)`
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 25px 25px 50px 25px;
`;

const CommandArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.documentsDetailsCommandAreaBG};
  display: flex;
  justify-content: center;
  visibility: ${props => (props.isDragging === false ? "visible" : "hidden")};
`;

const UploadButtonContainer = styled.div`
  margin: ${props => props.margin};
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const UploadButton = styled(FileUploadInput)`
  top: 168px;
  left: 0;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 100px;
  height: 44px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on;
  color: #ffffff;
  background: #000000;
  cursor: pointer;
  outline: 0;
  border: 0;
`;

const UploadHint = styled.div`
  margin-top: 8px;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const DropAreaIndicator = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(252, 252, 252, 0.97);
  border: 2px dashed rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  visibility: ${props => (props.isDragging === true ? "visible" : "hidden")};
`;

const DropAreaHint = styled.div`
  align-self: center;
  white-space: pre-wrap;
  font-size: 11px;
  font-weight: 800;
  line-height: 13.31px;
  text-align: center;
  opacity: 0.5;
  text-transform: uppercase;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const SubTitleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const AbortButton = styled(SecondaryButton)`
  min-width: 40px;
  padding-left: 20px;
  padding-right: 20px;
  height: 33px;
`;

const SubTitle = styled.div`
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
`;

const UploadLoader = styled(FancyLoader)`
  flex: 1;
  margin-top: 10px;
`;

const ExtractedDataDescription = styled.div`
  margin-top: 15px;
  color: #7a7a7a;
  white-space: pre-wrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

const RescanButton = styled(SecondaryButton)`
  min-width: 50px;
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;

const ImportTabs = styled(Tabs)`
  margin-top: 25px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  white-space: nowrap;
`;

const ImportTabList = styled(TabList)`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
  padding-bottom: 8px;
`;

const ImportTab = styled(Tab)`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.35);
  padding-bottom: 8px;
  margin-right: 25px;
  border: 0;
  cursor: pointer;
  z-index: 100;

  &.is-selected {
    color: black;
    border-bottom: 2px solid rgba(0, 0, 0, 1);
  }
`;

const ImportTabPanel = styled(TabPanel)`
  margin-top: -10px;
  display: none;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  &.is-selected {
    display: flex;
    flex: 1;
  }
`;

const NoResultsMessage = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;
  letter-spacing: 0%;
  white-space: pre-line;
`;

class AiDocumentImportComponent extends React.Component {
  static show = (history, location, custodianId) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.AI_DOCUMENT_IMPORT}&${hashParams.ID}=${custodianId}`
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      isDragging: false,
      isUploading: false,
      files: null,
      error: null,
      dataExtracted: null,
      documentApiData: null,
      isDataPartial: false,
      tabIndex: 0
    };

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragStop = this.handleDragStop.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleRescanButtonClick = this.handleRescanButtonClick.bind(this);
    this.setTab = this.setTab.bind(this);

    if (!this.props.files === false) {
      setTimeout(() => {
        this.handleFileUpload(this.props.files);
      }, 10);
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.files !== this.props.files) {
      setTimeout(() => {
        this.handleFileUpload(this.props.files);
      }, 10);
    }
  }

  handleOverlayDismiss(showConfirmation = true) {
    if (showConfirmation) {
      this.setState({ showDismissConfirmation: true });
    } else {
      this.props.onDismiss();
      DialogOverlay.forceDismiss(this.props.history, this.props.location);
    }
  }

  handleDragStart() {
    this.setState({ isDragging: true });
  }

  handleDragStop() {
    this.setState({ isDragging: false });
  }

  handleExtractedDataApiResponse(apiData) {
    this.setState({
      isUploading: false,
      dataExtracted: apiData.dataExtracted,
      isDataPartial: apiData.statusInfo === "MAX_TOKENS"
    });
  }

  async handleFileUpload(files) {
    let allowAiImport = await this.props.showBlackPaywallIfQualifies(AI_DOCUMENT_IMPORT_PAYWALL_ACTION);
    if (!allowAiImport) return;

    if (!files === true || files.length === 0) {
      return;
    }
    if (files[0].size > MAX_FILE_SIZE || !this.props.aiSupportedFileTypes.includes(files[0].type)) {
      this.setState({ error: FILE_UNSUPPORTED_ERROR });
      return;
    }

    this.setState({ files, isUploading: true, dataExtracted: null, isDataPartial: false });
    this.props
      .uploadAiImportDocument(this.props.currentPortfolio.id, files[0])
      .then(apiData => {
        this.setState({ documentApiData: apiData });
        return this.props.getAiImportExtractedData(apiData.id);
      })
      .then(apiData => {
        this.handleExtractedDataApiResponse(apiData);
      })
      .catch(apiError => {
        console.log(apiError);
        this.setState({ isUploading: false, error: apiError });
      });
  }

  getFileName(file, removeExtension = false) {
    return capitalizeStringWithSpaces(removeExtension ? getFileNameWithoutExtension(file.name) : file.name);
  }

  handleRescanButtonClick(e) {
    this.setState({ isUploading: true, dataExtracted: null, isDataPartial: false });

    this.props
      .rescanAiImportDocument(this.state.documentApiData.id)
      .then(apiData => {
        this.setState({ documentApiData: apiData });
        return this.props.getAiImportExtractedData(apiData.id);
      })
      .then(apiData => {
        this.handleExtractedDataApiResponse(apiData);
      })
      .catch(apiError => {
        console.log(apiError);
        this.setState({ isUploading: false, error: apiError });
      });
  }

  isGenericError(error) {
    return error.errorCode !== apiErrorCodes.RATE_LIMIT_EXCEEDED && error.errorCode !== apiErrorCodes.EMPTY_RESPONSE;
  }

  getErrorTitle(error) {
    if (this.state.error === FILE_UNSUPPORTED_ERROR) {
      return i18n.t("aiImport.fileNotSupportedTitle");
    }
    return this.isGenericError(error)
      ? i18n.t("aiImport.genericErrorTitle")
      : i18n.t("aiImport.talkToHumansErrorTitle");
  }

  getErrorDescription(error) {
    if (this.state.error === FILE_UNSUPPORTED_ERROR) {
      return i18n.t("aiImport.fileNotSupportedDesc");
    }
    const isPdfFile = this.state.files[0]?.type === "application/pdf";
    if (this.isGenericError(error)) {
      return isPdfFile ? i18n.t("aiImport.genericPdfErrorDesc") : i18n.t("aiImport.genericErrorDesc");
    }
    return isPdfFile ? i18n.t("aiImport.talkToHumansPdfErrorDesc") : i18n.t("aiImport.talkToHumansErrorDesc");
  }

  getTabs() {
    console.log(this.state.dataExtracted);

    return [
      {
        title: i18n.t("aiImportTab.custodian"),
        key: importTabs.CUSTODIAN,
        itemCount: this.state.dataExtracted.account.asset.length + this.state.dataExtracted.account.debt.length,
        panelComponent: (
          <AiCustodianImportComponent
            defaultSheetId={this.props.defaultSheetId}
            isDataPartial={this.state.isDataPartial}
            dataExtracted={this.state.dataExtracted.account}
            onRescan={this.handleRescanButtonClick}
          />
        )
      },
      {
        title: i18n.t("aiImportTab.cashflow"),
        key: importTabs.CASHFLOW,
        itemCount: this.state.dataExtracted.cashFlow.cashIn.length + this.state.dataExtracted.cashFlow.cashOut.length,
        panelComponent: (
          <AiCashflowImportComponent
            isDataPartial={this.state.isDataPartial}
            dataExtracted={this.state.dataExtracted.cashFlow}
            onRescan={this.handleRescanButtonClick}
          />
        )
      },
      {
        title: i18n.t("aiImportTab.capitalCalls"),
        key: importTabs.CAPITAL_CALL,
        itemCount: this.state.dataExtracted.capitalCall.length,
        panelComponent: (
          <AiFundScheduleImportComponent
            isDataPartial={this.state.isDataPartial}
            dataExtracted={this.state.dataExtracted.capitalCall}
            onRescan={this.handleRescanButtonClick}
          />
        )
      },
      {
        title: i18n.t("aiImportTab.distributions"),
        key: importTabs.DISTRIBUTION,
        itemCount: this.state.dataExtracted.distributionSchedule.length,
        panelComponent: (
          <AiFundScheduleImportComponent
            isDataPartial={this.state.isDataPartial}
            dataExtracted={this.state.dataExtracted.distributionSchedule}
            onRescan={this.handleRescanButtonClick}
          />
        )
      }
    ];
  }

  setTab(tabIndex) {
    this.setState({
      tabIndex
    });
  }

  render() {
    if (this.state.dataExtracted) {
      const tabs = this.getTabs();

      return (
        <DialogOverlay onDismiss={this.handleOverlayDismiss}>
          <ImportDialog>
            <Container>
              <ContentContainer>
                <HeaderContainer>
                  <SubTitleContainer>
                    <SubTitle>{i18n.t("aiImport")}</SubTitle>
                    <Header>
                      <Title>{this.getFileName(this.state.files[0], true)}</Title>
                      <Preview
                        onClick={e => {
                          const fileWindow = window.open(URL.createObjectURL(this.state.files[0]), "_blank");
                          const name = this.state.files[0].name;
                          fileWindow.addEventListener("load", function() {
                            fileWindow.document.title = name;
                          });
                        }}
                      >
                        {i18n.t("preview")}
                      </Preview>
                    </Header>
                  </SubTitleContainer>
                  <AbortButton onClick={() => this.handleOverlayDismiss()} title={i18n.t("abort")} />
                </HeaderContainer>

                <ImportTabs
                  selectedTabClassName="is-selected"
                  selectedTabPanelClassName="is-selected"
                  selectedIndex={this.state.tabIndex}
                  onSelect={this.setTab}
                >
                  <ImportTabList>
                    {tabs.map((tab, index) => (
                      <ImportTab data-cy={"aiImportTab" + index} key={index}>
                        {`${tab.title} (${tab.itemCount})`}
                      </ImportTab>
                    ))}
                  </ImportTabList>
                  {tabs.map((tab, index) => (
                    <ImportTabPanel key={`aiImportTabsPanel${index}`}>
                      {tab.itemCount === 0 ? (
                        <NoResultsMessage>
                          {i18n.t("aiImport.noAccountResults").replace("%s1%", tab.title)}
                        </NoResultsMessage>
                      ) : (
                        tab.panelComponent
                      )}
                    </ImportTabPanel>
                  ))}
                </ImportTabs>

                <ExtractedDataDescription
                  dangerouslySetInnerHTML={{
                    __html: i18n.t("aiImport.importRowsDesc")
                  }}
                />
              </ContentContainer>
              {this.state.showDismissConfirmation && (
                <ConfirmationDialog
                  canUserDismiss={false}
                  title={i18n.t("aiImport.abort")}
                  description={i18n.t("areYouSure")}
                  positiveButtonTitle={i18n.t("abort")}
                  negativeButtonTitle={i18n.t("stay")}
                  handlePositiveButtonClick={e => this.handleOverlayDismiss(false)}
                  handleNegativeButtonClick={e => this.setState({ showDismissConfirmation: false })}
                />
              )}
            </Container>
          </ImportDialog>
        </DialogOverlay>
      );
    }

    if (this.state.isUploading) {
      return (
        <DialogOverlay onDismiss={this.handleOverlayDismiss}>
          <ImportDialog>
            <Container>
              <ContentContainer>
                <HeaderContainer>
                  <SubTitleContainer>
                    <SubTitle>{i18n.t("aiImport")}</SubTitle>
                    <Title>{this.getFileName(this.state.files[0], true)}</Title>
                  </SubTitleContainer>
                  <AbortButton onClick={() => this.handleOverlayDismiss(true)} title={i18n.t("abort")} />
                </HeaderContainer>
                <UploadLoader
                  initialMessage={i18n.t("aiImport.waitMessage")}
                  footerMessage={i18n.t("aiImport.waitHint")}
                />
              </ContentContainer>
              {this.state.showDismissConfirmation && (
                <ConfirmationDialog
                  canUserDismiss={false}
                  title={i18n.t("aiImport.abort")}
                  description={i18n.t("areYouSure")}
                  positiveButtonTitle={i18n.t("abort")}
                  negativeButtonTitle={i18n.t("stay")}
                  handlePositiveButtonClick={e => this.handleOverlayDismiss(false)}
                  handleNegativeButtonClick={e => this.setState({ showDismissConfirmation: false })}
                />
              )}
            </Container>
          </ImportDialog>
        </DialogOverlay>
      );
    }

    return (
      <DialogOverlay onDismiss={() => this.handleOverlayDismiss(false)}>
        <ImportDialog>
          <Container>
            <DocumentUploadContainer
              onDragStart={this.handleDragStart}
              onDragStop={this.handleDragStop}
              onFileDrop={this.handleFileUpload}
              disabled={this.props.isReadOnly === true}
            >
              <CommandArea isDragging={this.state.isDragging}>
                <ContentContainer>
                  {!this.state.error === true && (
                    <>
                      <AiDocumentImportIconComponent />
                      <Title>{i18n.t("aiDocumentImportDialog.title")}</Title>
                      <Description
                        dangerouslySetInnerHTML={{
                          __html: this.props.userPreferences.isAiDocumentImportTipShown
                            ? i18n.t("aiDocumentImportDialog.desc")
                            : i18n.t("aiDocumentImportDialog.firstTimeDesc")
                        }}
                      />
                      <UploadButtonContainer margin={"44px 0px 0px 0px"}>
                        <UploadButton label={i18n.t("uploadFile")} multiple={false} onChange={this.handleFileUpload} />
                        <UploadHint>{i18n.t("uploadFileDrop")}</UploadHint>
                      </UploadButtonContainer>
                    </>
                  )}
                  {!this.state.error === false && (
                    <>
                      {this.state.error === FILE_UNSUPPORTED_ERROR ? (
                        <AiDocumentImportIconComponent />
                      ) : (
                        <AiDocumentImportErrorIconComponent />
                      )}
                      <SubTitle>{i18n.t("aiImport")}</SubTitle>
                      <Title>{this.getErrorTitle(this.state.error)}</Title>
                      {this.state.error !== FILE_UNSUPPORTED_ERROR && (
                        <ErrorPreview
                          onClick={e => {
                            const fileWindow = window.open(URL.createObjectURL(this.state.files[0]), "_blank");
                            const name = this.state.files[0].name;
                            fileWindow.addEventListener("load", function() {
                              fileWindow.document.title = name;
                            });
                          }}
                        >
                          {this.state.files[0].name}
                        </ErrorPreview>
                      )}
                      <Description
                        dangerouslySetInnerHTML={{
                          __html: this.getErrorDescription(this.state.error)
                        }}
                      />
                      <ActionButtonsContainer>
                        <UploadButtonContainer margin={"0px 0px 0px 0px"}>
                          <UploadButton
                            label={
                              this.state.error === FILE_UNSUPPORTED_ERROR
                                ? i18n.t("uploadFile")
                                : i18n.t("uploadAnotherFile")
                            }
                            multiple={false}
                            onChange={this.handleFileUpload}
                          />
                          <UploadHint>{i18n.t("uploadFileDrop")}</UploadHint>
                        </UploadButtonContainer>
                        <RescanButton onClick={this.handleRescanButtonClick} title={i18n.t("rescan")} />
                      </ActionButtonsContainer>
                    </>
                  )}
                </ContentContainer>
                <DropAreaIndicator isDragging={this.state.isDragging}>
                  <DropAreaHint>{i18n.t("aiImportDropFileHint")}</DropAreaHint>
                </DropAreaIndicator>
              </CommandArea>
            </DocumentUploadContainer>
          </Container>
        </ImportDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentPortfolio: currentPortfolioSelector(state),
  defaultTargetSheet: sheetSelector(state, props.defaultSheetId),
  userPreferences: userPreferencesSelector(state),
  aiSupportedFileTypes: aiSupportedFileTypesSelector(state)
});

const mapDispatchToProps = {
  uploadAiImportDocument,
  getAiImportExtractedData,
  rescanAiImportDocument,
  showBlackPaywallIfQualifies
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AiDocumentImportComponent));
