import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  uploadAiImportDocument,
  currentPortfolioSelector,
  getAiImportExtractedData,
  getUuid,
  getTickerUsingId,
  store,
  custodianSelector,
  sheetSelector,
  sectionSelector,
  updateCustodian,
  getTickerUsingShortName,
  insertCustodianAtEndOfSection,
  updateDashboardAction,
  insertSection,
  updateUserPreferences,
  userPreferencesSelector,
  PVST_VALUE_TICKER_ID,
  aiSupportedFileTypesSelector,
  updateAiImportDocumentIgnoredStatus,
  rescanAiImportDocument,
  showBlackPaywallIfQualifies,
  showToastTip,
  fetchTickerDetails,
  convertPVSTRateToValueExchangeRate,
  getCustodianHistoryFormattedDateString,
  convertCustodianHistoryApiDateFormatToUIFormat,
  sheetSectionsSelector
} from "@kubera/common";
import { withRouter } from "@kubera/common";
import SecondaryButton from "components/button/SecondaryButton";
import PrimaryButton from "components/button/PrimaryButton";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  cellType
} from "components/grid/GridDataModel";
import ContextMenu, { contextMenuItemType } from "components/contextmenu/ContextMenu";
import { ReactComponent as DownArrow } from "assets/images/menu_downarrow.svg";
import CustodiansPickerDialog from "components/planning/variable_dialogs/CustodiansPickerDialog";
import { category } from "components/dashboard/DashboardComponentExports";
import { itemState, getBadgeColorForItemState } from "./AiImportExports";
import { ReactComponent as OptionsIcon } from "assets/images/options.svg";
import expandableIndicatorIcon from "assets/images/expandable_indicator.svg";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  margin-top: 30px;
`;

const ImportButton = styled(PrimaryButton)`
  min-width: 50px;
  padding-left: 20px;
  padding-right: 20px;
  background: #029600;
`;

const RescanButton = styled(SecondaryButton)`
  min-width: 50px;
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;

const ResultsContainer = styled.div`
  margin-top: 22px;
`;

const GridContainer = styled.div`
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;
  margin: ${props => props.margin};
`;

const PartialResultMessage = styled.div`
  color: #333333;
  padding: 10px 20px 10px 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 0%;
  background: #ffe600;
`;

const Grid = styled(GridComponentWrapper)`
  flex: 1;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
`;

const BadgeContainer = styled.div`
  display: flex;
  margin-left: -4px;
  margin-right: -15px;
  min-width: 30px;
`;

const BadgeText = styled.div`
  display: flex;
  height: 15px;
  background: ${props => props.bgColor};
  color: ${props => (props.itemState === itemState.NEW ? "#FFFFFF" : "#00000099")};
  border: ${props =>
    props.itemState === itemState.IGNORED || props.itemState === itemState.UNKNOWN ? "1px solid #BCBCBC" : "none"};
  width: 100%;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 15px;
  text-align: right;
  font-weight: 400;
  font-size: 9px;
  line-height: 9px;
  letter-spacing: 0%;
  text-transform: uppercase;
`;

const SectionTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 10px 12px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const ExpandIcon = styled.div`
  width: 9px;
  height: 19px
  margin-right: 16px;
  background-color: transparent;
  background-image: url(${expandableIndicatorIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 9px 9px;
  transform: ${props => (props.isCollapsed ? "rotate(270deg)" : "rotate(0deg)")};
`;

const SectionDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionName = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0%;
`;

const SectionDescription = styled.div`
  margin-top: 3px;
  font-weight: 400;
  font-size: 10px;
  line-height: 125%;
  white-space: pre-line;
  letter-spacing: 0%;
  color: ${props => (props.isCustodianPath ? "#6F6F6F" : "#0074FC")};
`;

const SectionOptionsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 40px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  margin-right: -10px;
  margin-left: auto;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.gridCellSelectedOutlineColor};
    outline-offset: -1px;
  }
`;

const OptionsIconComponent = styled(OptionsIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

class AiFundScheduleImportComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataExtracted: null,
      custodianNameCashflowMap: null,
      gridData: null,
      showLocationPickerForSection: null,
      showContextMenuForSection: null
    };

    this.locationMap = {};
    this.defaultLocationMap = {};
    this.ignoredItemIds = [];
    this.ignoredCustodianNames = [];
    this.contextMenuRef = React.createRef();

    this.handleGridChange = this.handleGridChange.bind(this);
    this.handleRowContextMenuSelection = this.handleRowContextMenuSelection.bind(this);
    this.handleSectionLocationSelection = this.handleSectionLocationSelection.bind(this);
    this.handleSectionLocationPickerDismiss = this.handleSectionLocationPickerDismiss.bind(this);
    this.handleImportButtonClick = this.handleImportButtonClick.bind(this);
    this.handleGridRowUpdate = this.handleGridRowUpdate.bind(this);
    this.handleSectionNameClick = this.handleSectionNameClick.bind(this);
    this.handleSectionOptionButtonClick = this.handleSectionOptionButtonClick.bind(this);
    this.handleSectionContextMenuSelection = this.handleSectionContextMenuSelection.bind(this);
  }

  componentDidMount() {
    this.handleExtractedDataResponse(this.props.dataExtracted);
  }

  handleExtractedDataResponse(dataExtracted) {
    const custodianNameCashflowMap = {};
    for (const item of dataExtracted) {
      if (!custodianNameCashflowMap[item.name] === true) {
        custodianNameCashflowMap[item.name] = [];
      }
      custodianNameCashflowMap[item.name].push(item);
      if (item.ignore === true) {
        this.ignoredItemIds.push(asset.id);
      }
    }
    for (const name in custodianNameCashflowMap) {
      const cashflows = custodianNameCashflowMap[name];
      cashflows.sort((a, b) => b.date.localeCompare(a.date));

      const custodian = custodianSelector(
        store.getState(),
        cashflows[0].matchedCustodianId,
        this.props.currentPortfolio.id
      );
      if (!custodian === false) {
        const section = sectionSelector(store.getState(), custodian?.sectionId);
        const sheet = sheetSelector(store.getState(), section?.sheetId);

        if (custodian && section && sheet) {
          this.locationMap[name] = {
            isCustodian: true,
            section: section,
            sheet: sheet,
            ...custodian
          };
        }
      }
    }

    this.defaultLocationMap = { ...this.locationMap };
    this.setState({
      dataExtracted: dataExtracted,
      custodianNameCashflowMap: custodianNameCashflowMap,
      gridData: this.getGridData(this.props.currentPortfolio.currency, custodianNameCashflowMap)
    });
  }

  getRowCountToImport() {
    if (!this.state.gridData === true) {
      return 0;
    }
    const mappedSectionNames = Object.keys(this.locationMap);
    return this.state.gridData.sheets[0].sections.filter(item => mappedSectionNames.includes(item.name)).length;
  }

  handleGridChange(newGridData) {
    this.setState({ gridData: newGridData });
  }

  updateGridRow(gridData, newRow, sheetIndex, sectionIndex, rowIndex) {
    const newGridData = gridData;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = newRow;
    this.setState({ gridData: newGridData });
  }

  handleGridRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) {
    if (!updatedRow.cells[3].value === false && updatedRow.cells[3].value !== updatedRow.cells[5].value) {
      updatedRow.cells[5].value = updatedRow.cells[3].value;
      this.setState({ gridData: this.state.gridData });
    }
  }

  handleCellInvalidTickerAdded(gridData, sheetIndex, sectionIndex, rowIndex, cellIndex) {
    const row = gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];

    if (!cell.invalidInputText === false && cell.loading === false) {
      let invalidInputText = cell.invalidInputText;
      if (cell.getTickerId() === PVST_VALUE_TICKER_ID) {
        const rateParsed = cell.rate;
        invalidInputText = rateParsed ? getTickerUsingId(rateParsed.t).shortName : cell.currency;
      }
      cell.loading = true;
      this.updateGridRow(gridData, row.clone(), sheetIndex, sectionIndex, rowIndex);
      this.props.fetchTickerDetails(
        invalidInputText,
        new Date(),
        result => {
          const row = gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];

          cell.loading = false;
          if (!result === true) {
            this.props.showToastTip("TIP", i18n.t("invalidTickerError"), null, 10000);
            this.updateGridRow(gridData, row.clone(), sheetIndex, sectionIndex, rowIndex);
            return;
          }

          if (cell.getTickerId() === PVST_VALUE_TICKER_ID) {
            cell.exchangeRateDetails = this.props.convertPVSTRateToValueExchangeRate(
              cell.rate,
              getCustodianHistoryFormattedDateString(new Date().getTime())
            );
            cell.currency = "PVST";
            cell.invalidInputText = null;
          } else {
            cell.exchangeRateDetails = result.exchangeRateDetails;
            cell.currency = result.tickerShortName;
            cell.invalidInputText = null;
          }

          const newRow = row.clone();
          this.updateGridRow(gridData, newRow, sheetIndex, sectionIndex, rowIndex);
        },
        error => {
          const row = gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];
          cell.loading = false;
          this.updateGridRow(gridData, row.clone(), sheetIndex, sectionIndex, rowIndex);
          this.props.showToastTip("TIP", i18n.t("tickerFetchFailure"), null, 10000);
        }
      );
    }
  }

  getCashflowDetails(cashflow) {
    if (cashflow.quantity !== undefined && cashflow.tickerId) {
      return {
        value: cashflow.quantity,
        valueTickerId: cashflow.tickerId,
        currency: getTickerUsingId(cashflow.tickerId).shortName
      };
    } else if (cashflow.value !== undefined && cashflow.valueTickerId) {
      return {
        value: cashflow.value,
        valueTickerId: cashflow.valueTickerId,
        currency: getTickerUsingId(cashflow.valueTickerId).shortName
      };
    }
  }

  setGridRowItemState(section, row) {
    if (this.ignoredItemIds.includes(row.id)) {
      row.cells[0].itemState = itemState.IGNORED;
    } else if (!this.locationMap[section.name] === true) {
      row.cells[0].itemState = itemState.UNKNOWN;
    } else {
      row.cells[0].itemState = itemState.NEW;
    }
  }

  populateGridRow(section, row, cashflow) {
    row.id = cashflow.id;
    row.cells[1].value = convertCustodianHistoryApiDateFormatToUIFormat(cashflow.date);
    row.cells[2].value = cashflow.description;

    const cashflowDetails = this.getCashflowDetails(cashflow);
    row.cells[3].value = cashflowDetails.value;
    row.cells[3].currency = cashflowDetails.currency;
    row.cells[3].exchangeRateDetails = cashflowDetails.valueExchangeRate;
    this.setGridRowItemState(section, row);
  }

  getGridData(currency, custodianNameCashflowMap) {
    var sections = [];
    for (const [index, [name, cashflows]] of Object.entries(Object.entries(custodianNameCashflowMap))) {
      const section = this.getEmptySection(name, `${index + 1}`);
      sections.push(section);

      var rows = [];
      for (const [index, cashflow] of cashflows.entries()) {
        const row = this.getEmptyRow(`${index + 1}`, cashflow);
        this.populateGridRow(section, row, cashflow);
        rows.push(row);
      }
      section.rows = rows;
    }

    const sheet = this.getEmptySheet("1");
    sheet.sections = sections;

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;
    return gridData;
  }

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getLocationPath(location) {
    if (!location === true) {
      return i18n.t("aiImport.pickCashflowCustodian");
    }
    if (location.isCustodian) {
      const isSingleSectionSheet =
        sheetSectionsSelector(store.getState(), this.props.currentPortfolio.id, location.sheet.id).length === 1;
      const path = isSingleSectionSheet ? location.sheet.name : `${location.sheet.name} / ${location.section.name}`;
      return `${path}\n${location.name}`;
    }
    return null;
  }

  getEmptySection(name, sortKey) {
    const badgeColumn = new GridColumnData("", false, false, true);
    badgeColumn.width = "40px";
    const dateColumn = new GridColumnData(i18n.t("date"), true, true, false);
    const notesColumn = new GridColumnData(i18n.t("notes"), true, true, false);
    const amountColumn = new GridColumnData(i18n.t("amount"), true, true, false);
    const optionsColumn = new GridColumnData(null, false, false, true);
    const columns = [badgeColumn, dateColumn, notesColumn, amountColumn, optionsColumn];

    const sectionData = new GridSectionData(getUuid(), sortKey, name, [], columns, undefined, 4, false);
    sectionData.getContextMenuItems = section => {
      return [
        [this.ignoredCustodianNames.includes(section.name) ? contextMenuItemType.UNIGNORE : contextMenuItemType.IGNORE]
      ];
    };
    sectionData.forceShowTitle = true;
    sectionData.showFooter = false;
    sectionData.isDragDisabled = true;

    sectionData.onRenderTitle = (sheetIndex, sectionIndex, section) => {
      const location = this.locationMap[section.name];

      return (
        <SectionTitleContainer>
          <ExpandIcon isCollapsed={section.isCollapsed} />
          <SectionDetailsContainer onClick={e => this.handleSectionNameClick(e, section)}>
            <SectionName>
              {section.name}
              <DownArrow />
            </SectionName>
            <SectionDescription isCustodianPath={!location === false && location.isCustodian === true}>
              {this.getLocationPath(location)}
            </SectionDescription>
          </SectionDetailsContainer>
          <SectionOptionsButton onClick={e => this.handleSectionOptionButtonClick(e, section)}>
            <OptionsIconComponent />
          </SectionOptionsButton>
        </SectionTitleContainer>
      );
    };
    return sectionData;
  }

  getEmptyRow(sortKey, cashflow) {
    const badgeCell = new GridCellData(cellType.CLICKABLE_TEXT, "", null);
    badgeCell.width = "40px";
    badgeCell.textAlignment = "right";
    badgeCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const cell = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex];
      return (
        <BadgeContainer>
          <BadgeText bgColor={getBadgeColorForItemState(cell.itemState)} itemState={cell.itemState}>
            {cell.itemState}
          </BadgeText>
        </BadgeContainer>
      );
    };
    const dateCell = new GridCellData(cellType.TEXT, i18n.t("date"), null);
    dateCell.textAlignment = "left";

    const notesCell = new GridCellData(cellType.TEXT, i18n.t("notes"), null);
    notesCell.textAlignment = "left";
    notesCell.emptyValueIndicator = "---";

    const amountCell = new CurrencyCellData(
      cellType.CURRENCY,
      i18n.t("amount"),
      null,
      this.props.currentPortfolio.currency
    );
    amountCell.emptyValueIndicator = "---";
    const optionCell = new GridCellData(cellType.OPTIONS, "", null);
    const cells = [badgeCell, dateCell, notesCell, amountCell, optionCell];

    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 2, false, () => {
      return true;
    });
    rowData.showHint = false;
    rowData.getContextMenuItems = (row, rowIndex) => {
      return [
        [contextMenuItemType.RESET_CHANGES],
        [row.cells[0].itemState === itemState.IGNORED ? contextMenuItemType.UNIGNORE : contextMenuItemType.IGNORE]
      ];
    };
    return rowData;
  }

  handleRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, menuItem) {
    const section = this.state.gridData.sheets[sheetIndex].sections[sectionIndex];

    if (menuItem.id === contextMenuItemType.RESET_CHANGES.id) {
      const newGridData = this.state.gridData;
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = row;
      const cashflow = this.state.dataExtracted.find(item => item.id === row.id);
      this.locationMap[section.name] = this.defaultLocationMap[section.name];
      this.populateGridRow(section, row, cashflow);
      this.setState({ gridData: newGridData });
    } else if (menuItem.id === contextMenuItemType.IGNORE.id) {
      const newGridData = this.state.gridData;
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = row;
      row.cells[0].itemState = itemState.IGNORED;
      this.ignoredItemIds.push(row.id);
      this.setState({ gridData: newGridData });
    } else if (menuItem.id === contextMenuItemType.UNIGNORE.id) {
      const newGridData = this.state.gridData;
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = row;
      this.ignoredItemIds = this.ignoredItemIds.filter(item => item !== row.id);
      this.setGridRowItemState(section, row);
      this.setState({ gridData: newGridData });
    }
  }

  handleSectionNameClick(e, section) {
    e.stopPropagation();
    this.setState({ showLocationPickerForSection: section });
  }

  handleSectionOptionButtonClick(e, section) {
    e.stopPropagation();

    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = e.target.getBoundingClientRect();
    const menuItems = section.getContextMenuItems(section);

    this.contextMenuRef.current.show(
      menuItems,
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height + 2,
      false,
      e.target
    );
    this.setState({ showContextMenuForSection: section });
  }

  handleSectionContextMenuSelection(item) {
    const section = this.state.showContextMenuForSection;

    if (item.id === contextMenuItemType.IGNORE.id) {
      const ignoredItemIds = section.rows.map(row => row.id);
      this.ignoredItemIds.push(...ignoredItemIds);
      this.ignoredCustodianNames.push(section.name);
      for (const row of section.rows) {
        this.setGridRowItemState(section, row);
      }
      this.setState({ showContextMenuForSection: null, gridData: this.state.gridData });
    } else if (item.id === contextMenuItemType.UNIGNORE.id) {
      const unignoredItemIds = section.rows.map(row => row.id);
      this.ignoredItemIds = this.ignoredItemIds.filter(item => !unignoredItemIds.includes(item));
      this.ignoredCustodianNames = this.ignoredCustodianNames.filter(item => item !== section.name);
      for (const row of section.rows) {
        this.setGridRowItemState(section, row);
      }
      this.setState({ showContextMenuForSection: null, gridData: this.state.gridData });
    }
  }

  handleSectionLocationPickerDismiss() {
    this.setState({ showLocationPickerForSection: null, showLocationPickerForDebtRow: null });
  }

  handleSectionLocationSelection(section, value) {
    this.locationMap[section.name] = value;

    for (const row of section.rows) {
      this.setGridRowItemState(section, row);
    }
    this.setState({ gridData: this.state.gridData });
  }

  handleImportButtonClick(e) {}

  render() {
    if (this.state.dataExtracted) {
      const gridOptions = {
        getRowStyle: (row, rowIndex, sectionIndex) => {
          return {
            height: "45px",
            backgroundColor: row.cells[0].itemState === itemState.IGNORED ? "#EBEBEB" : "white"
          };
        }
      };

      const rowCountToImport = this.getRowCountToImport();
      const importButtonString = i18n.t("aiImport.importRows").replace("%s1%", rowCountToImport);
      const totalResults = this.state.dataExtracted.length;

      return (
        <Container>
          <ContentContainer>
            <ActionButtonsContainer>
              <ImportButton
                onClick={this.handleImportButtonClick}
                title={rowCountToImport > 1 ? importButtonString : importButtonString.slice(0, -1)}
              />
              <RescanButton onClick={this.props.onRescan} title={i18n.t("rescan")} />
            </ActionButtonsContainer>
            <ResultsContainer>
              {this.props.isDataPartial === true && (
                <PartialResultMessage>
                  {i18n.t("aiImport.partialResultMessage").replace("%s1%", Math.floor((totalResults - 8) / 5) * 5)}
                </PartialResultMessage>
              )}
              {this.state.gridData && this.state.gridData.sheets[0].sections.length > 0 && (
                <GridContainer>
                  <Grid
                    gridOptions={gridOptions}
                    gridData={this.state.gridData}
                    getEmptyRow={this.getEmptyRow}
                    onChange={this.handleGridChange}
                    onRowUpdate={this.handleGridRowUpdate}
                    onSectionUpdate={() => {}}
                    onCellInvalidTickerAdded={(sheetIndex, sectionIndex, rowIndex, cellIndex) =>
                      this.handleCellInvalidTickerAdded(
                        this.state.gridData,
                        sheetIndex,
                        sectionIndex,
                        rowIndex,
                        cellIndex
                      )
                    }
                    onRowContextMenuSelection={this.handleRowContextMenuSelection}
                  />
                </GridContainer>
              )}
            </ResultsContainer>
          </ContentContainer>
          <ContextMenu
            ref={this.contextMenuRef}
            width={233}
            onSelection={this.handleSectionContextMenuSelection}
            onDismiss={() => this.setState({ showContextMenuForSection: null })}
          />
          {!this.state.showLocationPickerForSection === false && (
            <CustodiansPickerDialog
              category={category.ASSET}
              subTitle={this.state.showLocationPickerForSection.name}
              allowCategoryChange={false}
              assetDescription={i18n.t("aiImport.assetPickerDesc")}
              filterResults={result => {
                return result.isCustodian === true;
              }}
              returnResultWithDetails={true}
              onVariableUpdate={data =>
                this.handleSectionLocationSelection(this.state.showLocationPickerForSection, data.items[0])
              }
              onDismiss={this.handleSectionLocationPickerDismiss}
            />
          )}
        </Container>
      );
    }
  }
}

const mapStateToProps = (state, props) => ({
  currentPortfolio: currentPortfolioSelector(state),
  defaultTargetSheet: sheetSelector(state, props.defaultSheetId),
  userPreferences: userPreferencesSelector(state),
  aiSupportedFileTypes: aiSupportedFileTypesSelector(state)
});

const mapDispatchToProps = {
  uploadAiImportDocument,
  getAiImportExtractedData,
  updateCustodian,
  insertCustodianAtEndOfSection,
  insertSection,
  updateDashboard: updateDashboardAction,
  updateUserPreferences,
  updateAiImportDocumentIgnoredStatus,
  rescanAiImportDocument,
  showBlackPaywallIfQualifies,
  showToastTip: showToastTip,
  fetchTickerDetails: fetchTickerDetails,
  convertPVSTRateToValueExchangeRate: convertPVSTRateToValueExchangeRate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AiFundScheduleImportComponent));
