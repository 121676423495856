export const itemState = {
  UPDATE: "UPDATE",
  SAME: "SAME",
  NEW: "NEW",
  IGNORED: "IGNORED",
  UNKNOWN: ""
};

export const getBadgeColorForItemState = state => {
  if (state === itemState.NEW) {
    return "#FF0202";
  }
  if (state === itemState.UPDATE) {
    return "#AFF2D8";
  }
  if (state === itemState.SAME) {
    return "#DCDCDC";
  }
  if (state === itemState.IGNORED) {
    return "#EBEBEB";
  }
  if (state === itemState.UNKNOWN) {
    return "#FFFFFF";
  }
};
